import * as d3 from 'd3'

type DateFormatter = (date: Date) => string

interface XTicksOptions {
  startDate: Date
  endDate: Date
  tickCount: number
  formatter: DateFormatter
}

export function generateXTicks({ startDate, endDate, tickCount, formatter }: XTicksOptions): Date[] {
  // normalize dates to the start of day
  startDate = d3.timeDay.floor(startDate)
  endDate = d3.timeDay.floor(endDate)

  // use d3 ticks for edge cases:
  // 1. when data range is in the same month
  if (d3.timeMonth.count(startDate, endDate) === 0) {
    return d3.scaleTime().domain([startDate, endDate]).ticks(tickCount)
  }

  if (tickCount < 2) tickCount = 2

  const displayPrecision = checkDateDisplayPrecison(formatter)
  const timeRange = endDate.getTime() - startDate.getTime()
  const step = timeRange / (tickCount - 1)
  const startTime = startDate.getTime()
  const ticks: Date[] = []
  let stepCounter = 0

  // populate ticks with requested tick count
  while (stepCounter < tickCount) {
    const actualTime = new Date(startTime + step * stepCounter)
    // apply a deviation base on displayPrecision
    const roundedTime = stepCounter === 0 ? actualTime : displayPrecision.round(actualTime)
    // to avoid duplicate ticks in roundedTicks, this happens when tickCount is high
    if (roundedTime.getTime() !== ticks[ticks.length - 1]?.getTime()) ticks.push(roundedTime)
    stepCounter++
  }

  // ensure the last tick is the end date
  if (ticks[ticks.length - 1].getTime() !== endDate.getTime()) {
    ticks[ticks.length - 1] = endDate
  }

  return ticks
}

/**
 * Determines the time precision (year/month/day) based on the formatter output
 */
export function checkDateDisplayPrecison(formatX: DateFormatter): d3.CountableTimeInterval {
  if (!formatX) return d3.timeMonth

  const formattedBase = formatX(new Date(2020, 0, 15))

  // Compare with next day
  if (formattedBase === formatX(new Date(2020, 0, 16))) {
    // If days format the same, check months
    return formattedBase === formatX(new Date(2020, 1, 15))
      ? d3.timeYear // Same format across months = year precision
      : d3.timeMonth // Different format across months = month precision
  }

  // default to month precision, as we don't expcet to see the precise day of the month
  return d3.timeMonth
}
