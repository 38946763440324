<template>
  <div class="plot-bar"></div>
</template>

<script>
import { SCALE_5 } from './plottable'

const plot_create = (
  trigger = console.log,
  {
    variant = 'stacked-bar',
    color = 'var(--colors-primary-default)',
    //xAxisSize = 25,
    yAxisSize = 45,
    labelsEnabled = false,
    xDomain,
    yDomain,
  },
) => {
  const xScale = new Plottable.Scales.Category().innerPadding(0.1).outerPadding(0.1)
  if (xDomain) {
    xScale.domain(xDomain)
  }
  const xAxis = new Plottable.Axes.Category(xScale, 'bottom').tickLabelPadding(2).margin(0).tickLabelMaxLines(1)
  const yScale = new Plottable.Scales.Linear().padProportion(0.2)
  if (yDomain) {
    yScale.domain(yDomain)
  }
  const yAxis = new Plottable.Axes.Numeric(yScale, 'left').tickLabelPadding(2).margin(0)
  //xAxis._computeHeight = () => xAxisSize
  yAxis._computeWidth = () => yAxisSize
  yScale.tickGenerator(SCALE_5)

  const plot = new Plottable.Plots[variant.camelize()]()
    .x(d => d.x, xScale)
    .y(d => d.y, yScale)
    .attr('class', (d, i, ds) => {
      const filters = $root.$route.query[ds.metadata().dimension]
      const active =
        !filters ||
        filters.length === 0 ||
        filters.split('|').includes(ds.metadata().activedim ? d[ds.metadata().activedim] : d.label)
      return [
        d.class,
        ds.class,
        d.label.replace(/.*(fund|benchmark3|benchmark2|benchmark|diff).*/g, '$1'),
        d.x.replace(/.*(fund|benchmark3|benchmark2|benchmark|diff).*/g, '$1'),
        +d.y > 0 ? 'positive' : 'negative',
        active ? 'active' : 'inactive',
      ].join(' ')
    })
    .attr('fill', color)
    .labelsEnabled(labelsEnabled)
    .animated(false)

  if (labelsEnabled) {
    plot._hideBarsIfAnyAreTooWide = false
    plot._labelsPosition = 'outside'
    plot._overflowHidden = false
    // try {
    //   debugger
    //   let _drawLabel_original = Plottable.Plots.Bar.prototype._drawLabel;
    //   Plottable.Plots.Bar.prototype._drawLabel = (...args) => {
    //     _drawLabel_original(...args);
    //     return true;
    //   }
    // } catch (e) {
    //   console.error(e);
    // }
    Plottable.Plots.Bar._LABEL_PADDING = 0
  }

  const gridlines = new Plottable.Components.Gridlines(xScale, yScale)

  const waterlabel = new Plottable.Plots.StackedBar()
    .x(d => d.x, xScale)
    .y(d => d.y, yScale)
    .attr('class', 'waterlabel')
    .labelsEnabled(true)

  const watermark = new Plottable.Plots.Segment()
    .x(d => xScale.scale(d.x) - xScale.rangeBand() / 2)
    .x2(d => xScale.scale(d.x) + xScale.rangeBand() / 2)
    .y(d => d.y, yScale)
    .y2(d => d.y, yScale)
    .attr('class', d => 'watermark ' + d.x + ' ' + (+d.y > 0 ? 'positive' : 'negative'))
    .autorangeMode('none')

  const group =
    variant === 'stacked-bar'
      ? new Plottable.Components.Group([gridlines, plot, waterlabel, watermark])
      : new Plottable.Components.Group([gridlines, plot])

  const table = new Plottable.Components.Table([
    [size() === 'mobile' ? null : yAxis, group],
    [null, xAxis],
  ])

  let tooltip = null
  const attach = component => {
    new Plottable.Interactions.Pointer()
      .attachTo(component)
      .onPointerMove(p => {
        if (!plot.entityNearest(p)) return
        if (eq(tooltip, plot.entityNearest(p).datum)) return
        tooltip = plot.entityNearest(p).datum
        trigger('tooltip', tooltip)
        // const tick_number = Math.floor(p.x / xAxis._scale.stepWidth())
        // const tick_value = xAxis._scale.domain()[tick_number]
        // const tick_labels = xAxis._content.selectAll('.tick-label text')
        // const ds = plot.datasets()[0]
        // const filters = $root.$route.query[ds.metadata().dimension]
        // tick_labels.each(function (value, i, elements) {
        //   d3.select(this).classed('active', filters.split('|').includes($root.t.__.find(elements[i].innerHTML)))
        //   d3.select(this).classed('hover', elements[i].innerHTML === $root.t[tick_value])
        // })
        plot.entities().__.map(entity => entity.selection.classed('hover', false))
        plot.entityNearest(p).selection.classed('hover', true)
      })
      .onPointerExit(() => {
        tooltip = null
        trigger('tooltip', null)
        plot.entities().__.map(entity => entity.selection.classed('hover', false))
        // xAxis._content.selectAll('.tick-label text').each(function (value) {
        //   d3.select(this).classed('hover', false)
        // })
      })

    new Plottable.Interactions.Click().attachTo(component).onClick(p => {
      if (!plot.entityNearest(p)) return
      const d = plot.entityNearest(p).datum
      trigger('plotclick', d, component === plot ? plot.entitiesAt(p) : [])
    })
  }
  attach(plot)
  attach(xAxis)
  plot.datasets((0).upto(100).__.map(() => new Plottable.Dataset()))
  window.addEventListener('resize', () => table.redraw())

  return Object.freeze({ table, plot, waterlabel, watermark, xAxis, yAxis, xScale, yScale })
}

export default {
  props: {
    data: Object,
    metadata: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['tooltip', 'mounted', 'plotclick', 'rebind'],
  data() {
    return {
      components: null,
      variant: 'stacked-bar',
    }
  },
  mounted() {
    // const ctx = this.$parent.$parent.$options.name === 'brick' ? this.$parent.$parent : this.$parent.$options.name === 'block' ? this.$parent : this
    this.trigger = this.$emit.bind(this)
    // this.trigger = console.log.bind(console)
    this.variant = this.metadata && this.metadata.variant
    this.components = plot_create(this.trigger, this.metadata)
    this.components.table.renderTo(this.$el)
    // Remove this, use only rebind event ?
    this.trigger('mounted', this.components)
    this.trigger('rebind', this.components)

    // if (!ctx.$listeners.plotclick)
    //

    this.rebind()
  },
  watch: {
    data: 'rebind',
    metadata(next) {
      if (next.__.keys().length) this.rebind()
    },
  },
  methods: {
    rebind() {
      if (!this.components || !this.data) return
      let _data = this.data
      let { group, sort, max, suppressEmptyBars } = this.metadata
      if (suppressEmptyBars && _data instanceof Object) _data = _data.__.filter()
      if (max) {
        // Adjust max in case there are less categories than max.
        max = Math.min(max, _data.__.keys().length)
      }
      const length = _data.__.map((values, name) => (type(values) === 'object' ? values.__.keys().length : 1))
      let xy = _data.__.reduce((acc, values, name) => {
        if (type(values) === 'object') values.__.map((v, k) => acc.push({ x: name, y: v, label: k }))
        if (type(values) === 'number') acc.push({ x: name, y: values, label: name })
        return acc
      }, [])
        .group(group || 'x')
        .__.v()
        .sort(sort || (d => -Math.abs(d.sum('y'))))

      if (max && xy.length > 1) {
        const not_other = xy.slice(0, max).flat(Infinity).__.map('x')
        group = d => (not_other.includes(d.x) ? d.x : 'other')
      }
      xy = xy
        .flat(Infinity)
        .group(group || 'x')
        .__.reduce((acc, grp, name) => {
          if (this.variant !== 'clustered-bar') grp = grp.sort(d => -d.y)
          if (name === 'other' && type(_data.__.v().first()) === 'object') {
            // if input type is object of objects; agregate 'other'
            grp = grp
              .group(d => d.label.split(',')[0])
              .__.map((v, k) => ({ x: v[0].label.split(',')[0], y: v.sum('y') }))
              .__.v()
          }
          grp.__.map((d, i) => {
            acc[i] = acc[i] || []
            if (d.x === d.label) d.x = name
            else if (name === 'other') {
              d.label = d.x
              d.x = name
            }
            acc[i].push(d)
          })
          return acc
        }, [])

      const sum = v => {
        if (typeof v === 'number') return v
        return (Array.isArray(v) ? v : Object.values(v)).reduce((a, b) => a + b, 0)
      }
      if (max && !sort) xy[0] = xy[0].sort(d => (d.x === 'other' ? Infinity : (_data[d.x] && -sum(_data[d.x])) || -d.y)) // ! here: check with laurent

      // HACK: DIRTY quick hack for default formatting '+'
      let defaultf =
        xy
          .flat(Infinity)
          .__.filter(d => d.y)
          .__.map(d => Math.abs(d.y))
          .median() < 0.005
          ? 'bp'
          : '.2%'

      const plus =
        $root.query.metric === 'diff' || ($root.query.evolution && $root.screen.calendar === 'daily') ? '+' : ''
      const f = plus + (this.metadata.format || defaultf)
      const f2 = f.replace ? format(f.replace(/\.\d/, '.1')) : f

      let defaultValueFormatter = (value, d) => {
        return `${value}`
      }
      let defaultAxisFormatter = (value, d) => {
        // let formattedValue = (value === 0 ? value : f2(value).replace(/[.,]0/, ''))
        let formattedValue = f2(value).replace(/[.,]0/, '')
        return formattedValue
      }

      let { valueFormatter, axisFormatter, valueFormat } = this.metadata
      if (!valueFormatter) valueFormatter = defaultValueFormatter
      if (!axisFormatter) axisFormatter = defaultAxisFormatter
      if (valueFormat) {
        valueFormatter = (value, d) => {
          return format(valueFormat)(value)
        }
      }
      const waterlabel = xy
        .flat(Infinity)
        .group('x')
        .__.map((grp, k) => ({ x: k, y: grp.sum('y') }))
        .__.v()
        .__.filter(d => d.y != 0)
      this.components.xAxis.formatter(x => this.t[x] || x)
      this.components.plot.labelFormatter(valueFormatter)
      if (this.variant !== 'clustered-bar') this.components.plot.extremaFormatter(x => '')
      this.components.waterlabel.labelFormatter(x => '')
      this.components.waterlabel.extremaFormatter(format(f))
      this.components.yAxis.formatter(axisFormatter)

      this.components.plot.datasets(xy.map(_xy => new Plottable.Dataset(_xy)))
      this.components.plot.datasets().__.map((ds, i) => {
        // ds._data = xy[i] || []
        ds._metadata = this.metadata
      })
      // if (this.variant === 'clustered-bar')
      this.components.plot.datasets(this.components.plot.datasets().__.filter(ds => ds.data().length))
      this.components.waterlabel.datasets([new Plottable.Dataset(waterlabel)])
      this.components.watermark.datasets([new Plottable.Dataset(waterlabel)])
      this.trigger('rebind', this.components)
    },
  },
}
</script>
