<template>
  <builder-block v-if="props" :data="props.data" :options="props.options" :context="props.context">
    <cjs-bubble-chart
      class="w-full"
      :data="formattedData"
      :options="chartOptions"
      v-if="formattedData?.keys()"
    ></cjs-bubble-chart>
  </builder-block>
</template>

<script setup>
import { computed, defineProps, toRaw, reactive, watch } from 'vue'
import { getXValues, formatOptions } from '../composables/builderOptions'
import { bubble } from './stories'
import useTranslations from '../composables/translations'
import { legendNames } from '../composables/builderOptions'
import { getStylesheetPalette } from '../composables/builderComponent'
import { generatePaletteLinear } from '../lib/linearGradientColorCalculate'
import { formatFactory, resolvePostFormat } from '../lib/format'

const definedProps = defineProps(['data', 'options', 'context'])
const { translate, translateData } = useTranslations(definedProps)

const DEFAULT_BUBBLE_PALETTE = ['#46CDC9', '#084081']

const options = computed(() => ({
  palette: getStylesheetPalette(definedProps.context.stylesheet, definedProps.context.node.palette),
  ...definedProps.options,
}))

const props = reactive({
  ...definedProps,
  data: translateData.value(definedProps.data),
  options: options.value,
})

watch(
  () => [definedProps.options, definedProps.context.stylesheet],
  () => {
    props.options = options.value
  },
  { deep: false },
)

const axisMembers = computed(() => axis => {
  return props.data?.map(d => d[props.options[axis]]).filter((d, i, arr) => arr.indexOf(d) === i) || []
})

const axisFormatter = computed(() => axis => {
  const sample = axisMembers.value(axis)[0]

  if (typeof sample === 'number') {
    const lang = props.context?.variables?.lang
    const shareCurrency = props.context?.variables?.shareCurrency
    const postFormat = resolvePostFormat(props.context.globalPostFormat, props.options?.postFormat)
    const formatFn = formatFactory({
      unit: props.options?.unit,
      digit: props.options?.digit,
      notation: props.options?.notation,
      lang,
      postFormat,
      symbol: props.options?.symbol,
      shareCurrency,
    })
    return formatFn
  }
  return value => value
})

const xMembers = computed(() => axisMembers.value('x'))
const yMembers = computed(() => axisMembers.value('y'))
const zMembers = computed(() => {
  return axisMembers.value('z') || props.data?.map(d => d.weight || 0).filter((d, i, arr) => arr.indexOf(d) === i) || []
})

const xFormatter = computed(() => axisFormatter.value('x'))
const yFormatter = computed(() => axisFormatter.value('y'))

const xLabels = computed(() => {
  return typeof axisMembers.value('x')[0] === 'string' ? axisMembers.value('x') : []
})

const yLabels = computed(() => {
  return typeof axisMembers.value('y')[0] === 'string' ? axisMembers.value('y') : []
})

const xAxisTitle = computed(() => translate.value(props.options.xTitle) || '')
const yAxisTitle = computed(() => translate.value(props.options.yTitle) || '')

const datasetGroups = computed(() => axisMembers.value('groupBy'))

const datasets = computed(() => {
  if (!datasetGroups.value) {
    return
  }

  const datasetGroupsLength = datasetGroups.value.length === 1 ? 2 : datasetGroups.value.length

  const bubblePalette = generatePaletteLinear(
    options.value.palette?.[0] || DEFAULT_BUBBLE_PALETTE[0],
    options.value.palette?.[1] || DEFAULT_BUBBLE_PALETTE[1],
    datasetGroupsLength,
  )

  return datasetGroups.value.map((d, i) => {
    return {
      label: getLegendLabelFromOptions(d),
      key: d,
      backgroundColor: bubblePalette[i],
    }
  })
})

const formattedData = computed(() => {
  if (!(datasetGroups.value && xMembers.value.length && yMembers.value.length && zMembers.value.length)) return []

  const zAvg = zMembers.value.reduce((sum, num) => sum + num, 0) / zMembers.value.length

  return datasetGroups.value.reduce((acc, _, i) => {
    acc[datasetGroups.value[i]] = props.data
      .filter(d => d[props.options.groupBy] === datasetGroups.value[i])
      .map(d => {
        return {
          x: typeof d[props.options.x] === 'string' ? xMembers.value.indexOf(d[props.options.x]) : d[props.options.x],
          y: typeof d[props.options.y] === 'string' ? yMembers.value.indexOf(d[props.options.y]) : d[props.options.y],
          r: props.options.bubbleSize
            ? props.options.bubbleSize
            : props.options.z && props.options.z === 'bubble_size'
              ? d[props.options.z]
              : (d[props.options.z] / zAvg) * 15,
        }
      })
    return acc
  }, {})
})

const chartOptions = computed(() => ({
  formatX: xFormatter.value,
  formatY: yFormatter.value,
  xLabels: xLabels.value,
  yLabels: yLabels.value,
  xAxisTitle: xAxisTitle.value,
  yAxisTitle: yAxisTitle.value,
  datasets: toRaw(datasets.value),
  legend: props.options.legend,
  titleTextSize: props.options.titleTextSize,
  mergeableOptions: {
    animations: false,
    hideExternalTooltip: true,
    events: [],
  },
}))

const getLegendLabelFromOptions = label => {
  const customName = props.options?.legendNames?.[label]?.name
  return customName ? translate.value(customName) : label
}
</script>

<script>
export default {
  api: {
    x: {
      label: 'X Axis',
      select: ({ data_component }) => {
        return data_component && Object.entries(data_component[0] || {}).map(([k, v]) => k)
      },
      default: data => getXValues(data)[0],
      attrs: {
        required: true,
      },
    },
    y: {
      label: 'Y Axis',
      select: ({ data_component }) => {
        return data_component && Object.entries(data_component[0] || {}).map(([k, v]) => k)
      },
      default: data => getXValues(data)[1],
      attrs: {
        required: true,
      },
    },
    groupBy: {
      label: 'Group By',
      select: ({ data_component }) => {
        return (
          data_component &&
          Object.entries(data_component[0] || {})
            .filter(([k, v]) => typeof v === 'string')
            .map(([k, v]) => k)
        )
      },
      default: data => getXValues(data)[0],
      attrs: {
        required: true,
      },
    },
    ...formatOptions,
    xTitle: {
      label: 'X Axis Title',
      dataList: ({ context }) => {
        return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
      },
    },
    yTitle: {
      label: 'Y Axis Title',
      dataList: ({ context }) => {
        return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
      },
    },
    titleTextSize: {
      label: 'Title Size',
      default: () => 12,
      attrs: {
        type: 'number',
      },
    },
    bubbleSize: {
      label: 'Bubble Size',
      default: () => 20,
      attrs: {
        type: 'number',
      },
    },
    legend: {
      label: 'Legend',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    legendNames,
  },
  styles: {},
  story: bubble,
}
</script>
