import { LayoutNode, ComponentNode } from '../../builder'
export function isComponentNode(node: LayoutNode | ComponentNode): node is ComponentNode {
  return node?.type === 'block' || 'component' in node
}
export function parseNode(
  node: LayoutNode | ComponentNode,
  transform: (node: LayoutNode | ComponentNode) => LayoutNode | ComponentNode,
): LayoutNode | ComponentNode {
  if (!node) return node
  const transformedNode = transform(node)
  // If its a ComponentNode
  if (isComponentNode(transformedNode)) {
    return transformedNode
  }
  if (transformedNode.nodes) {
    return {
      ...transformedNode,
      nodes: transformedNode.nodes.map(node => parseNode(node, transform) as LayoutNode),
    }
  }
  return transformedNode
}
