<template>
  <builder-block class="ecofi-manager items-center" :data="data" :options="options" :context="context">
    <div v-if="!managerData">No data</div>
    <div
      :class="[
        'flex',
        options.layoutDirection === 'row' ? 'flex-row flex-wrap' : 'flex-col',
        'gap-4',
        'manager-container',
      ]"
    >
      <div
        v-for="(manager, index) in managerData"
        :key="index"
        :class="[
          'flex',
          options.imagePosition === 'top' || options.imagePosition === 'bottom'
            ? 'flex-col items-center'
            : 'flex-row items-center',
          `index-${index}`,
        ]"
      >
        <div
          v-if="options.imagePosition === 'top' || options.imagePosition === 'left'"
          class="relative"
          :class="options.imagePosition === 'left' ? 'mr-4' : 'mb-2'"
        >
          <div class="image-container">
            <img class="image" :src="imageUrl(manager.image)" alt="Manager Image" />
          </div>
        </div>
        <div class="information flex flex-col">
          <div>
            <div v-if="options.nameOrder === '1 line: name firstname'" class="">
              <span class="firstname">{{ manager.first_name + ' ' }}</span>
              <span class="name">{{ manager.last_name }}</span>
            </div>
            <div v-else-if="options.nameOrder === '1 line: firstname name'" class="">
              <span class="name">{{ manager.last_name + ' ' }}</span>
              <span class="firstname">{{ manager.first_name }}</span>
            </div>
            <div v-else-if="options.nameOrder === '2 lines: name on top'" class="">
              <div class="name">{{ manager.last_name }}</div>
              <div class="firstname">{{ manager.first_name }}</div>
            </div>
            <div v-else-if="options.nameOrder === '2 lines: firstname on top'" class="">
              <div class="firstname">{{ manager.first_name }}</div>
              <div class="name">{{ manager.last_name }}</div>
            </div>
          </div>
          <div v-if="options.showTitle" class="title">
            {{ manager.title || 'title' }}
          </div>
        </div>
        <div
          v-if="options.imagePosition === 'bottom' || options.imagePosition === 'right'"
          class="relative"
          :class="options.imagePosition === 'right' ? 'ml-4' : 'mt-2'"
        >
          <div class="image-container">
            <img class="image" :src="imageUrl(manager.image)" alt="Manager Image" />
          </div>
        </div>
      </div>
    </div>
  </builder-block>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getAssetPublicUrl } from '@100-m/hauru/src/applications/builder/lib/assetManagement'
import { managerImage as story } from './stories'

const { data, options, context } = defineProps(['data', 'options', 'context'])
function translate(value: string) {
  return context.translations[value] || value
}

const managerData = computed(() => {
  return data.managers || []
})

function imageUrl(image: string) {
  return getAssetPublicUrl({ filenameWithPath: image })
}
</script>

<script lang="ts">
export default {
  api: {
    nameOrder: {
      label: 'Name order',
      default: () => '1 line: name firstname',
      select: () => {
        return ['1 line: name firstname', '1 line: firstname name', '2 lines: name on top', '2 lines: firstname on top']
      },
      attrs: {
        type: 'text',
      },
    },
    imagePosition: {
      label: 'Image position',
      default: () => 'top',
      select: () => {
        return ['top', 'bottom', 'left', 'right']
      },
    },
    showTitle: {
      label: 'Show job title',
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
      default: () => true,
    },
    layoutDirection: {
      label: 'Layout direction',
      default: () => 'row',
      select: () => {
        return ['row', 'column']
      },
    },
  },
  styles: {
    'manager-container': {
      name: 'Manager information container',
      css: `.index-0 {}`,
    },
    information: {
      name: 'Manager information',
      css: ``,
    },
    'image-container': {
      name: 'Manager image',
      css: ``,
    },
  },
  story,
}
</script>
