<template>
  <fieldset class="my-3 flex items-center text-sm">
    <slot name="label">
      <label class="w-32 shrink-0" v-if="label">{{ label }}</label>
    </slot>
    <select
      class="w-48 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
      v-model="value"
    >
      <option></option>
      <option v-for="(formatData, idx) in dateFormats" :key="idx" :value="formatData.value">
        {{ formatData.name }}
      </option>
    </select>
  </fieldset>
</template>
<script setup lang="ts">
import { dateFormats } from '../../lib/format'
defineProps(['context', 'label'])
const value = defineModel()
</script>
