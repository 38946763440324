<template>
  <builder-block class="grk-srri" :data="data" :options="options" :context="context">
    <div class="flex flex-col">
      <div class="flex flex-row gap-0.5">
        <div
          class="bg-primary max-w-10 flex h-8 w-full items-center justify-center text-white"
          :class="{ 'bg-secondary': i === srri }"
          v-for="i in [1, 2, 3, 4, 5, 6, 7]"
        >
          <span>{{ i }}</span>
        </div>
      </div>
      <div class="flex flex-row justify-between">
        <div>{{ t.higher_risk || 'Risque plus élevé' }}</div>
        <div>{{ t.lower_risk || 'Risque plus faible' }}</div>
      </div>
      <div class="font-bold">
        {{ t.key_risks || "Risques importants non pris en compte dans l'indicateur:" }}
      </div>
      <ul>
        <li>- {{ t.credit_risk || 'Risque de crédit spéculatif' }}</li>
        <li>- {{ t.counterPart_risk || 'Risque de contrepartie' }}</li>
        <li>
          -
          {{ t.derivative_risk || "Risque lié à l'impact des techniques telles que les produits dérivés" }}
        </li>
      </ul>
    </div>
  </builder-block>
</template>
<script setup lang="ts">
const { data, options, context } = defineProps(['data', 'options', 'context'])
function translate(value: string) {
  return context.translations[value] || value
}
const srri = +data?.srri
</script>

<style scoped></style>
