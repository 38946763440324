<template>
  <content-items folder-type="image" />
</template>

<script lang="ts">
export default {
  setup() {
    return {}
  },
}
</script>
