import { watch } from 'vue'
import { IGridColumn, IGridRow } from '@visage/types'
import { isEmpty } from 'lodash'
import { IGridItemData, IGridState } from '@common'
import { useGridState, initNxTheme, IGridMetadata } from '@hauru/common'

export const NX_SEPARATOR = '#/NX/#'

export const aggregationFunctions = {
  sum: (arr, k) =>
    arr.length
      ? +arr
          .map(v => +v?.[k] || 0)
          .sum()
          .toFixed(2)
      : '',
  avg: (arr, k) =>
    arr.length
      ? +arr
          .map(v => +v?.[k] || 0)
          .mean()
          .toFixed(2)
      : '',
  min: (arr, k) =>
    arr.length
      ? +arr
          .map(v => +v?.[k] || 0)
          .min()
          .toFixed(2)
      : '',
  max: (arr, k) =>
    arr.length
      ? +arr
          .map(v => +v?.[k] || 0)
          .max()
          .toFixed(2)
      : '',
  count: (arr, k) => arr.length,
  unique: (arr, k) => arr.map(k).unique().length,
  list: (arr, k) => arr.map(k).unique().sort().join(', '),
}

aggregationFunctions.values = aggregationFunctions.list
aggregationFunctions.maximum = aggregationFunctions.max
aggregationFunctions.minimum = aggregationFunctions.min

export function preparePivot(data, options = {}, agg = aggregationFunctions) {
  const { rows = [], columns = [], aggregates = [], withPercentage = false, withTotal = false } = options.pivot || {}
  const metadata = { tree: undefined }
  const [fname, cname] = aggregates[0] instanceof Array ? aggregates[0] : aggregates
  const reducer = data => agg[fname](data, cname)
  const sorter = data => {
    const sortFns = (options.columns?.sortBy?.list || []).map(
      sort => (a, b) =>
        default_sort(
          columns.concat(sort.label).reduce((v, p) => v?.[p], a),
          columns.concat(sort.label).reduce((v, p) => v?.[p], b),
        ) * sort.order,
    )
    return data.sort(sortFns.concat('id'))
  }
  if (!fname || !agg[fname]) return { data: [{ id: '-', '': '' }], metadata }
  if (!rows.length)
    return { data: [{ id: '-', [columns.join(' / ') || ' ']: group(data, columns, reducer) }], metadata }
  if (rows.length === 1) return { data: groupLevel(1), metadata }
  const groups = rows.map((_, i) => groupLevel(i + 1))
  return {
    data: groups.flat(),
    metadata: {
      tree: {
        type: 'node',
        id: '-1',
        is_virtual: true,
        children: groupFlat(0),
      },
    },
  }

  function default_sort(a, b) {
    if (typeof a !== typeof b) return typeof a > typeof b ? 1 : -1
    if (['object', 'function', 'undefined'].includes(typeof a)) return 0
    return a === b ? 0 : a > b ? 1 : -1
  }
  function flatten(obj) {
    if (obj.constructor === Array) return obj
    if (obj.constructor === Object) return Object.values(obj).flatMap(v => flatten(v))
  }
  function traverse(obj, reducer) {
    if (obj.constructor === Array) return reducer(obj)
    if (obj.constructor === Object)
      return { ...Object.map(obj, v => traverse(v, reducer)), default: reducer(flatten(obj)) }
  }
  function group(arr, fns, reducer) {
    if (!fns || !fns.length) return reducer(arr)
    fns = fns.map(fn => (fn.constructor === Function ? fn : v => v[fn]))
    const lastKeys = (reducer ? data : arr).map(fns.at(-1)).sort().unique()
    const otherKeys =
      fns.length > 1
        ? fns.slice(0, fns.length - 1).map(fn =>
            data
              .map(v => fn(v))
              .sort()
              .unique(),
          )
        : []

    let result = arr.reduce((acc, v) => {
      fns.reduce((acc, fn, i) => {
        const key = fn(v)
        const last = i === fns.length - 1
        if (last) {
          if (Object.keys(acc).length === 0) lastKeys.map(k => (acc[k] = acc[k] || []))
          return (acc[key] = acc[key].concat([v]))
        }
        const hasKey = Object.prototype.hasOwnProperty.call(acc, key)
        return (acc[key] = hasKey ? acc[key] : {})
      }, acc)
      return acc
    }, {})
    if (otherKeys.length) {
      otherKeys[0].forEach(key => {
        if (!result[key]) {
          result[key] = fillEmptyData(otherKeys.slice(1, otherKeys.length), lastKeys)
        } else {
          result[key] = fillEmptyData(otherKeys.slice(1, otherKeys.length), lastKeys, result[key])
        }
      })
    }
    if (!reducer) return result
    return traverse(result, reducer)
  }

  function fillEmptyData(keysList, lastKeys, data = {}) {
    if (!keysList.length) {
      return lastKeys.reduce((acc, lastKey) => {
        if (!acc[lastKey]) acc[lastKey] = ''
        return acc
      }, data)
    }
    return keysList[0].reduce((acc, key) => {
      if (!acc[key]) {
        acc[key] = fillEmptyData(keysList.slice(1, keysList.length), lastKeys)
      } else {
        acc[key] = fillEmptyData(keysList.slice(1, keysList.length), lastKeys, acc[key])
      }
      return acc
    }, data)
  }

  function groupLevel(depth) {
    const result = Object.entries(
      group(data, [
        v =>
          rows
            .slice(0, depth)
            .map(k => v[k])
            .join(NX_SEPARATOR),
      ]),
    ).map(([k, v]) => ({
      id: k === '' ? ' ' : k,
      [rows.join(' / ')]: k.split(NX_SEPARATOR)[depth - 1],
      [columns.join(' / ') || ' ']: group(v, columns, reducer),
    }))
    return sorter(result)
  }
  function groupFlat(depth, memo = {}) {
    if (depth === rows.length - 1)
      return groups[depth].map(v => ({
        id: v.id,
        type: 'row',
        data: v,
      }))
    return (memo[depth] =
      memo[depth] ||
      groups[depth].map(v => ({
        id: v.id,
        type: 'node',
        children: groupFlat(depth + 1, memo).filter(c => {
          return c.id.startsWith(v.id === ' ' ? NX_SEPARATOR : v.id)
        }),
        data: v,
      })))
  }
}

export function formatData(rows: IGridRow[], columns: IGridColumn[]) {
  return rows?.map((row, rowIndex) => {
    return row.cells.reduce(
      (acc, cell, index) => {
        if ((cell.value || isEmpty(cell.value)) && columns[index]) {
          acc[columns[index].options.label] = cell.value
        }
        return acc
      },
      { id: row?.row_id ?? rowIndex } as IGridItemData,
    )
  })
}

interface IGrid {
  data: IGridItemData[]
  metadata: IGridMetadata
}

function find(grid: IGrid, state: IGridState) {
  delete grid.metadata.matches
  if (!state.find) return
  const regex = RegExp(state.find).plus('ig')
  const matches = grid.data.reduce((acc, row) => {
    Object.entries(row).forEach(([k, v]) => {
      const match = regex.exec(v)
      if (!match) return
      if (!acc[row.id]) acc[row.id] = {}
      acc[row.id][k] = match
    })
    return acc
  }, {})
  grid.metadata.matches = matches
  grid.data = grid.data.filter((row, i) => matches[row.id])
}
function filter(grid: IGrid, state: IGridState) {
  if (!state.filter) return
  if (state.filter instanceof Function) return (grid.data = grid.data.filter(state.filter))
}
function sort(grid: IGrid, state: IGridState) {
  if (state.display !== 'grid') return
  if (state.customSortFn) return (grid.data = state.customSortFn(state, grid.data, state.metadata))
  const sortBy =
    state.columns.sortBy.list.length === 0
      ? 'id'
      : state.columns.sortBy.list.map(v => (v.order === -1 ? '-' + v.label : v.label))
  grid.data = grid.data.sort(sortBy)
}
function group(grid: IGrid, state: IGridState) {
  if (state.display !== 'grid') return
  const groupBy = state.columns.groupBy.list
  const columns = grid.metadata.columns || {}
  if (groupBy.length === 0) return (grid.metadata.tree = undefined)
  state.columns.setFreezedCount(1)
  const groups = grid.data.group(groupBy)
  const flat = (node, path = []) => {
    if (node instanceof Array) return node
    return node.__.reduce((acc, v, k) => {
      const arr = flat(v, path.concat(k))
      const leafs = arr.filter(v => !v.children)
      const aggregates = Object.fromEntries(
        Object.entries(leafs.at(-1)).map(([k2, v2]) => {
          const column = columns[k2]
          const aggregate = column?.aggregate
          const fn = aggregationFunctions[aggregate] || aggregate || (() => '')
          return [k2, fn(leafs, path.concat(k), state, k2)]
        }),
      )
      acc.push({ ...aggregates, id: path.concat(k).join('/') })
      acc.push(...arr)
      return acc
    }, [])
  }
  const tree = (node, path = []) => {
    if (node instanceof Array) return node.map(v => ({ type: 'row', id: v.id }))
    return node.__.map((v, k) => {
      return {
        type: 'node',
        id: path.concat(k).join('/'),
        children: tree(v, path.concat(k)),
      }
    }).__.v()
  }
  grid.data = flat(groups)
  grid.metadata.tree = {
    type: 'node',
    id: '-1',
    is_virtual: true,
    children: tree(groups),
  }
}
function transpose(grid: IGrid, state: IGridState) {
  if (state.display !== 'transpose') return
  state.columns.setFreezedCount(1)
  grid.data = grid.data.reduce((acc, row, i) => {
    Object.entries(row).forEach(([k, v], j) => {
      if (j === 0 && k === 'id') return
      const id = j - 1
      acc[id] = acc[id] || { id, k }
      acc[id]['c-' + i] = v
    })
    return acc
  }, [])
  grid.metadata.tree = undefined
}
function pivot(grid: IGrid, state: IGridState) {
  // if (state.display !== 'pivot' && grid.metadata.gridList) {
  //   state.columns.headers.empty() // TODO: avoid losing the previous header state
  //   // state.columns.headers.replace(grid.metadata.gridList)
  //   delete grid.metadata.gridList
  // }
  if (state.display !== 'pivot') return
  // if (!grid.metadata.gridList) {
  //   grid.metadata.gridList = JSON.parse(JSON.stringify(state.columns.headers.list))
  //   state.columns.headers.empty()
  // }
  state.columns.setFreezedCount(state.pivot.rows.length === 0 ? 0 : 1)
  const pivot = preparePivot(grid.data, state)
  grid.data = pivot.data
  grid.metadata = { ...grid.metadata, ...pivot.metadata }
  const index = state.columns.headers.list.findIndex(c => c.label === state.pivot.rows.join(' / '))
  state.columns.headers.move(index, 0) // work for index 0 or -1 without
}
const APIFunctions = {
  sort,
  group,
  pivot,
  transpose,
  find,
  filter,
}
// NX Grid - Simplified API

export function useGridAPI(options = {}) {
  if (options.init) initNxTheme()
  options = {
    rowHeight: 18, // 28
    freezedColumnsCount: 1,
    autoexpandColumns: false, // true
    showIndex: false, // true
    showCheckbox: true,
    enableCellSelection: false,
    enableItemSelection: true,
    selectionMode: 'combined',
    ...options,
  }
  const state = useGridState(options)
  const grid: IGrid = { data: [], metadata: {} as IGridMetadata }

  const { sort, group, pivot, transpose, find, filter } = { ...APIFunctions, ...state.APIFunctions }
  watch([() => state.data], () => {
    state.autoSizeColumns()
    if (state.metadata.watcher) return
    // console.log('STORE DATA')
    options.data = state.data
    options.metadata = state.metadata
  })
  watch(
    [
      state.columns.groupBy.list,
      state.columns.sortBy.list,
      () => state.display,
      () => state.pivot,
      () => state.find,
      () => state.filter,
    ],
    () => {
      // console.log('REFRESH DATA')
      grid.data = options.data.map((v, i) => ({ id: i, ...v }))
      grid.metadata = { ...options.metadata, watcher: true }
      // const columnFn = ([k, v], i) => [k, { type: k === 'id' ? 'id' : typeof v }]
      // const columns = Object.fromEntries(Object.entries(grid.data[0] || {}).map(columnFn))
      // grid.metadata.columns = grid.metadata.columns || columns
      // Object.values(grid.metadata.columns).forEach(column => column.aggregate = column.aggregate || aggregate)
      state.setShowIndex(state.display === 'grid' ? options.showIndex : false)
      state.setShowCheckbox(state.display === 'grid' ? options.showCheckbox : false)
      find(grid, state)
      filter(grid, state)
      transpose(grid, state)
      pivot(grid, state)
      sort(grid, state)
      group(grid, state)
      state.setGrid(grid)
    },
  )
  return state
}
