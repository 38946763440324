<template>
  <div class="image-content-form">
    <div class="row" style="gap: 16px; align-items: center">
      <button class="button primary" @click="triggerFileInput">
        {{ $root.t[`${props.folderType}_items_page`].import }}
      </button>
      <span class="filename">{{ props.fileName || $root.t[`${props.folderType}_items_page`].no_uploaded_file }}</span>
    </div>
    <input
      class="hidden"
      type="file"
      ref="fileInput"
      accept="image/jpeg, image/jpg, image/png"
      @change="handleFileChange"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{
  folderType: string
  fileName?: string
}>()

const fileInput = ref<HTMLInputElement | null>(null)

const emit = defineEmits<{
  (e: 'fileSelected', event: Event): void
}>()

function triggerFileInput() {
  fileInput.value?.click()
}

function handleFileChange(event: Event) {
  emit('fileSelected', event)
}
</script>

<style scoped>
.image-content-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filename {
  flex: 1;
}
</style>
