<template>
  <builder-block v-bind="{ data, options, context }" :class="context.isLast ? 'overflow-hidden' : ''" ref="block">
    <builderinternals-table
      class="nx-table"
      v-if="tableData"
      :data="formattedData"
      :columns="columns"
    ></builderinternals-table>
    <!-- <nx-table v-if="tableData" v-bind="nxChartProps"></nx-table> -->
  </builder-block>
  <!-- <builder-chart v-bind="{ ...props, nxChartOptions }">
    <template #header="tableProps">
      <tr class="table-header">
        <th class="table-header-cell" v-for="col in tableProps.columns" :key="col">
          <div v-if="col !== tableProps.options.x" v-html="tableProps.formatLabel(col)"></div>
          <div v-else-if="options.tableName" v-html="translate(options.tableName)"></div>
        </th>
      </tr>
    </template>
    <template #default="{ data, columns, options }">
      <tr class="table-row rounded-lg" v-for="(line, idx) in data" :key="idx">
        <td
          class="table-cell overflow-hidden whitespace-nowrap px-2 py-1"
          v-for="col in columns"
          :key="col"
          v-html="options.formatY(line[col])"
        ></td>
      </tr>
      <tr v-if="props?.options?.showTotal" class="table-row rounded-lg">
        <td
          class="table-cell overflow-hidden whitespace-nowrap px-2 py-1"
          v-for="col in columns"
          :key="col"
          v-html="options.formatY(computeTotalRow(data)?.[col])"
        ></td>
      </tr>
    </template>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart> -->
</template>

<style scoped></style>

<script setup lang="ts">
import { computed } from 'vue'
import { all, multiFormatOptions } from '../composables/builderOptions'
import { bar, bar as barStory } from './stories'
import { BuilderProps, useBuilderComponent } from '../composables/builderComponent'
import useTranslations from '../composables/translations'
import { validate } from '@100-m/hauru/src/components/common/nx-chart/nx-chart'
import { getUniqueCategories, pivotData } from '@100-m/hauru/src/components/common/nx-chart/chart/data-utils'
import { multiFormatFactory, avalaibleUnits, symbolFormats } from '../lib/format'

const viz = 'table'

const props = defineProps<BuilderProps>()
const { translate } = useTranslations(props)

const nxChartProps = useBuilderComponent(props, false)
const parsedProps = computed(() => {
  return validate({ data: nxChartProps.data, options: { ...nxChartProps.options, viz, lib: 'nx' } })
})
const categories = computed(() => getUniqueCategories(parsedProps.value.data, parsedProps.value.options))
const tableData = computed(() => pivotData(parsedProps.value.data, parsedProps.value.options, categories.value))
const columns = computed(() =>
  props.options?.columns?.map((col, idx) => {
    if (idx === 0 && props.options.tableName) {
      return { field: col, name: translate.value(props.options.tableName) }
    }
    return {
      field: col,
      name: translate.value(col),
    }
  }),
)
const formattedData = computed(() => {
  if (!tableData.value || !columns.value) return
  const data = tableData.value
  if (props.options.showTotal) {
    const total = columns.value.reduce(
      (acc, col, colIdx) => {
        if (colIdx === 0) {
          acc[col.field] = translate.value('total')
        }
        if (typeof data[0][col.field] === 'number') {
          acc[col.field] = data.reduce((acc, row) => acc + row[col.field], 0)
        }
        return acc
      },
      { _isTotal: true },
    )
    data.push(total)
  }

  // const stripedData = applyStriping(tableData.value.data, props.options.striping)
  return tableData.value.map((row, index) => {
    return Object.fromEntries(
      Object.entries(row).map(([key, value]) => {
        if (['_striped', '_level'].includes(key)) return [key, value]
        if (value === null) {
          return [key, '-']
        } else if (typeof value === 'number') {
          return [key, formatFn.value(value, key, row)]
        } else if (typeof value === 'string') {
          return [key, translate.value(formatFn.value(value, key, row))]
        } else if (typeof value === 'object') {
          return [key, Object.fromEntries(Object.entries(value).map(([k, v]) => [k, formatFn.value(v, key, row)]))]
        } else {
          return [key, value]
        }
      }),
    )
  })
})
const formatFn = computed(() => {
  const formatMode = props.options?.formatTable?.mode
  const lang = props.context?.variables?.lang
  const shareCurrency = props.context?.variables?.shareCurrency
  if (formatMode === 'table') {
    // const _formatFn =
    return multiFormatFactory({
      ...props.options.formatTable,
      lang,
      shareCurrency,
      postFormat: props.context.postFormat,
    })
  } else if (formatMode === 'columns' && props.options.formatTable.formats) {
    const formats = Object.fromEntries(
      Object.entries(props.options.formatTable.formats || {}).map(([col, formatData]) => {
        // console.log('col', col, 'formatData', formatData)
        return [col, multiFormatFactory({ ...formatData, lang, shareCurrency, postFormat: props.context.postFormat })]
      }),
    )
    return (v: number, col: string) => {
      return formats[col] ? formats[col](v) : v
    }
  }
  // else if (formatMode === 'rows' && props.options.formatTable.formats) {
  //   const formats = Object.fromEntries(
  //     Object.entries(props.options.formatTable.formats || {}).map(([name, formatData]) => {
  //       return [name, multiFormatFactory({ ...formatData, lang, shareCurrency, postFormat: props.context.postFormat })]
  //     }),
  //   )

  //   const rowName = props.data.labels[0]
  //   return (v: number, col: string, row: Record<string, any>) => {
  //     return formats[row[rowName]] ? formats[row[rowName]](v) : v
  //   }
  // }
  return (v: number, col: string, row?: any[]): string | number => v
})

function computeTotalRow(data: any[]) {
  return data.reduce(
    (acc, row) => {
      Object.keys(row).forEach(key => {
        if (typeof row[key] === 'number') {
          acc[key] = (acc[key] || 0) + row[key]
        }
      })
      return acc
    },
    { key: translate.value('total') },
  )
}
</script>

<script lang="ts">
function getColumns({ data_component, options: _options }) {
  if (!data_component || !data_component.length) return []
  const { data, options } = validate({ data: data_component, options: { ..._options, viz: 'table', lib: 'nx' } })
  if (!options || !data) return []
  return [options.x].concat(getUniqueCategories(data, options))
}
export default {
  api: {
    x: all.x,
    y: all.y,
    category: all.category,
    limit: all.limit,
    other: all.other,
    showTotal: {
      label: 'Show Total',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    columns: {
      label: 'Columns',
      multiSelect: {
        values: getColumns,
      },
      default: getColumns,
    },
    tableName: {
      label: 'Table Name',
      default: () => null,
      type: 'translationInput',
    },
    formatTable: {
      label: 'Format',
      modes: [
        {
          label: 'Format Table',
          mode: 'table',
          options: {
            formatType: {
              label: 'Type',
              default: () => 'number',
              select: () => ['', 'number', 'date'],
            },
            unit: {
              label: 'Unit',
              default: () => '',
              select: () => avalaibleUnits as string[],
              hide: ({ options }) => options?.formatTable?.formatType !== 'number',
            },
            symbol: {
              label: 'Symbol',
              default: () => '',
              hide: ({ options }) => !symbolFormats.includes(options?.formatTable?.unit),
            },
            digit: {
              label: 'Decimals',
              default: () => null,
              hide: ({ options }) => options?.formatTable?.formatType !== 'number',
              attrs: {
                type: 'number',
              },
            },
            notation: {
              label: 'Notation',
              default: () => 'compact',
              select: () => ['compact', 'standard'],
              hide: ({ options }) => options?.formatTable?.formatType !== 'number',
            },
            dateFormat: {
              label: 'Date format',
              default: () => 'standard',
              type: 'dateFormat',
              hide: ({ options }) => options?.formatTable?.formatType !== 'date',
            },
            customDateFormat: {
              label: 'Custom date format',
              default: () => 'yyyy-MM-dd',
              hide: ({ options }) =>
                options?.formatTable?.formatType !== 'date' || options?.formatTable?.dateFormat !== 'custom',
            },
          },
        },
        {
          label: 'Format Columns',
          mode: 'columns',
          options: {
            formats: {
              label: 'Column formats',
              multiple: {
                values: getColumns,
                options: multiFormatOptions,
              },
            },
          },
        },
      ],
    },
  },
  styles: {
    '.nx-table .table-header': {
      name: 'Table Header',
      css: `font-weight: bold;
.table-header-cell:first-of-type {
  text-align: left;
}`,
    },
    '.nx-table .table-row': {
      name: 'Table Row',
      css: `.table-cell:first-of-type {
  text-align: left;
}`,
    },
    '.nx-table .table-cell': {
      name: 'Table Cell',
      css: `padding: 4px 8px;
text-align: center;`,
    },
    '.nx-table .table-header-cell': {
      name: 'Table Header Cell',
      css: `padding: 4px 8px;
text-align: center;`,
    },
    '.nx-table.all-table': {
      name: 'Table',
      css: `
.table-row:nth-child(even) {
  background-color: color-mix(in srgb, var(--primary) 10%, transparent);
}`,
    },
  },
  story: {
    ...barStory,
    props: {
      ...barStory.props,
      options: {
        ...barStory?.props?.options,
        columns: ['key', 'Fund', 'Benchmark'],
      },
    },
  },
}
</script>
