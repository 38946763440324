<template>
  <!-- Keep min-h-0 to explicitly define children's height - enable auto-resizing with D3 chart -->
  <div
    class="builder-block brick container min-h-0 flex-1 p-2"
    v-if="options"
    ref="container"
    :class="{
      'overflow-hidden': context.isLast,
      'is-overflow': context.isLast && isOverflow,
      'min-h-fit': !context.isLast,
    }"
  >
    <div class="flex min-h-full flex-col gap-1" ref="element">
      <div class="heading1" v-if="options.title" v-html="translate(options.title)"></div>
      <div class="heading2" v-html="translate(options.subtitle)"></div>
      <div class="flex min-h-full flex-col" :class="{ 'flex-1': shouldShowDisclaimer && !context.virtual }">
        <!-- <div>{{ context.isLast }} - {{ isOverflow }}</div> -->
        <slot></slot>
      </div>
      <div
        class="disclaimer"
        v-if="(options.disclaimer || (blockFootnotes && blockFootnotes.length)) && shouldShowDisclaimer"
      >
        <div v-html="translate(options.disclaimer)"></div>
        <div v-if="blockFootnotes">
          <span v-for="(footnote, idx) in blockFootnotes" :key="idx">
            <sup>{{ footnote.index }}</sup>
            <span v-html="footnote.value"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { ResolvedBuilderOptions } from '../composables/builderOptions'
import useTranslations from '../composables/translations'
import useOverflow from '../composables/overflow'
const emit = defineEmits(['overflow'])

// TODO: have to put this override here else type checks fail
interface BuilderBlockProps {
  data?: any[] | Record<string, any[]>
  options: ResolvedBuilderOptions
  context: Record<string, any>
}
const container = ref<HTMLElement>()
const element = ref<HTMLElement>()
const props = defineProps<BuilderBlockProps>()
const { translate, blockFootnotes } = useTranslations(props)
function onOverflow(value: boolean) {
  if (autoOverflow.value) {
    emit('overflow', { isOverflow: value })
  }
}

const autoOverflow = computed(() => props.context.isLast && !props.context.node.disableAutoOverflow)
const { isOverflow, initOverflow, isProcessed: isOverflowProcessed } = useOverflow(element, container, onOverflow)

const shouldShowDisclaimer = ref(true)

let unwatchInitialized: (() => void) | null = null

// If component overflows, disclaimer should not be displayed inside this builder-block, but in the last element of the overflow
unwatchInitialized = watch(
  isOverflowProcessed,
  _isOverflowProcessed => {
    if (_isOverflowProcessed) {
      shouldShowDisclaimer.value = !isOverflow.value

      if (unwatchInitialized) {
        unwatchInitialized()
      }
    }
  },
  { immediate: true },
)

onMounted(() => {
  initOverflow()
})

defineExpose({ element, container })
</script>
<script lang="ts">
export default {
  styles: { heading1: '', heading2: '', disclaimer: '', container: '' },
  story: {
    props: {
      options: {
        title: 'Heading 1',
        subtitle: 'Heading 2',
        disclaimer: 'Disclaimer',
      },
      context: {
        translations: {},
      },
    },
    slot: `<div class="h-36">Content</div>`,
  },
}
</script>
