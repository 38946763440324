import { ResolvedBuilderOptions, unitFormat } from '../composables/builderOptions'
import { format } from 'date-fns'
import * as locales from 'date-fns/locale'

function getCurrency(currency?: string, shareCurrency?: string) {
  if (!currency) return
  if (currency === 'shareCurrency') return shareCurrency
  if (['$', '€', '£'].includes(currency)) return currency.replace('$', 'USD').replace('€', 'EUR').replace('£', 'GBP')
  if (currency.length === 3) return currency
}
function formatCurrency(
  num: number,
  {
    lang,
    symbol,
    digit,
    notation,
    shareCurrency,
    currencyDisplay,
  }: {
    lang: string
    symbol?: string
    digit: number
    notation?: 'standard' | 'compact'
    shareCurrency?: string
    currencyDisplay?: 'symbol' | 'code'
  },
) {
  const currency = getCurrency(symbol, shareCurrency)
  if (!currency) {
    return Intl.NumberFormat(lang.slice(0, 2), {
      notation,
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    }).format(num)
  }
  try {
    return Intl.NumberFormat(lang.slice(0, 2), {
      style: 'currency',
      currency,
      currencyDisplay,
      notation,
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    }).format(num)
  } catch (e: any) {
    if (e instanceof RangeError && e.message.includes('Invalid currency code')) {
      return `Invalid currency code (${currency})`
    }
    throw e
  }
}
export const avalaibleUnits: unitFormat[] = [
  null,
  'standard',
  'bp',
  '%',
  'base100',
  'currencySymbol',
  'currencyIsoCode',
  'prefix',
  'suffix',
]
// Formats that uses a symbol
export const symbolFormats: unitFormat[] = ['currencySymbol', 'currencyIsoCode', 'prefix', 'suffix']
export function formatFactory({
  unit,
  digit,
  notation,
  lang = 'en',
  postFormat = undefined,
  symbol,
  shareCurrency,
}: Partial<ResolvedBuilderOptions>) {
  if (!unit && (digit === null || digit === undefined || digit === '')) return (num: number) => num?.toString() || num
  notation = notation || 'compact'
  const formatFn = (num: number) => {
    if ((!num && num !== 0) || typeof num !== 'number') return num ? '' + num : ''
    digit = Math.max(0, Math.min(digit || 0, 4))
    if (unit === 'bp') return Math.round(num * 10000) + 'bp'
    if (unit === '%') {
      return (
        Intl.NumberFormat(lang.slice(0, 2), {
          notation,
          minimumFractionDigits: digit,
          maximumFractionDigits: digit,
        }).format(num * 100) + '%'
      )
    }
    if (unit === 'base100') {
      return Intl.NumberFormat(lang.slice(0, 2), {
        notation,
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }).format(num * 100)
    }
    if (unit === 'currencySymbol') {
      return formatCurrency(num, { lang, symbol, digit, notation, shareCurrency, currencyDisplay: 'symbol' })
    }
    if (unit === 'currencyIsoCode') {
      return formatCurrency(num, { lang, symbol, digit, notation, shareCurrency, currencyDisplay: 'code' })
    }
    const formattedValue = Intl.NumberFormat(lang.slice(0, 2), {
      notation,
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    }).format(num)
    if (unit === 'prefix') {
      return `${symbol || ''} ${formattedValue}`
    }
    if (unit === 'suffix') {
      return `${formattedValue} ${symbol || ''}`
    }
    return formattedValue
  }
  if (!postFormat) return formatFn
  return (num: number | string) => {
    // Dont post format / postformat strings
    if (typeof num === 'string') return num
    const postFormatFn = getPostFormatFn(postFormat)
    if (!postFormatFn) return formatFn(num)
    return postFormatFn({ formattedValue: formatFn(num), value: num, unit, digit, symbol })
  }
}

export const dateFormats = [
  {
    name: 'Standard',
    value: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    } as const,
  },
  {
    name: 'Short',
    value: {
      dateStyle: 'short',
    } as const,
  },
  {
    name: 'Medium',
    value: {
      dateStyle: 'medium',
    } as const,
  },
  {
    name: 'Long',
    value: {
      dateStyle: 'long',
    } as const,
  },
  {
    name: 'Full',
    value: {
      dateStyle: 'full',
    } as const,
  },
  {
    name: 'Year and Month',
    value: {
      year: 'numeric',
      month: '2-digit',
    } as const,
  },
  {
    name: 'Year only',
    value: {
      year: 'numeric',
    } as const,
  },
  {
    name: 'Custom format',
    value: 'custom' as const,
  },
] as const

export function dateFormatFactory(
  dateFormat: 'custom' | Intl.DateTimeFormatOptions,
  customDateFormat?: string,
  lang: string = 'en',
) {
  let _lang = lang?.replace('_', '-') || 'en'
  // remap ambiguous lang code if client config under the window object is exist
  const clientConfig = typeof window !== 'undefined' ? (window as any)?.config : undefined

  if (clientConfig?.defaultLocales?.[_lang]) _lang = clientConfig.defaultLocales[_lang]
  if (!dateFormat) return (date: Date | string) => new Date(date).toLocaleDateString(_lang)
  if (dateFormat === 'custom') {
    return (date: Date | string) =>
      format(new Date(date), customDateFormat || 'yyyy-MM-dd', { locale: getLocale(lang) })
  }

  return (date: Date | string) => Intl.DateTimeFormat(_lang, dateFormat).format(new Date(date))
}

type postFormatFunction = ({
  formattedValue,
  value,
  unit,
  digit,
  symbol,
}: {
  formattedValue: string
  value: number
  unit?: string
  digit?: number
  symbol?: string
}) => string

// Resolve postFormat, if component postFormat is default, take the global postFormat
export function resolvePostFormat(globalPostFormat?: string, postFormat?: string) {
  // Need to be able to explicitly disable postFormat
  if (postFormat === 'none') return
  return postFormat === 'default' || !postFormat ? globalPostFormat : postFormat
}

function getPostFormatFn(postFormatName: string) {
  // @ts-expect-error config is global
  if (!postFormatName || !config?.customFormats?.[postFormatName]) return
  // @ts-expect-error config is global
  return config?.customFormats?.[postFormatName] as postFormatFunction
}

export function multiFormatFactory(formatOptions: ResolvedBuilderOptions) {
  if (formatOptions?.formatType === 'date') {
    return dateFormatFactory(formatOptions?.dateFormat, formatOptions?.customDateFormat, formatOptions?.lang)
  }
  return formatFactory({
    ...formatOptions,
    lang: formatOptions?.lang,
    shareCurrency: formatOptions?.shareCurrency,
  })
}

/**
 * langs that not mapped in locales
 */
const fallbackLocale = {
  zh: locales.zhCN,
  en: locales.enUS,

  // no need to handle, as fr is already in locales
  // 'fr': locales.fr,
}

const getLocale = (lang: string) => {
  let [langPrefix, langSuffix] = lang.split('_')
  langPrefix = langPrefix?.toLowerCase()
  const resolvedLang = `${langPrefix}${langSuffix?.toUpperCase() || ''}`
  // try to resolve the locale by the following order fullInput -> langPrefix -> fallbackLocale -> enUS
  return locales[resolvedLang] || locales[langPrefix] || fallbackLocale[langPrefix] || locales.enUS
}
