import { commandrSDKFileSystem } from '../../../features/commandr'

export const getFileName = (path: string): string => {
  return path.split('/').at(-1) || ''
}

export const loadAssetInCache = (file: File): Promise<{ src: ArrayBuffer | string; size: string }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      if (!e.target?.result) {
        return reject(new Error('no target'))
      }

      const result = { src: e.target.result, size: Math.round(file.size / 1000) + ' ko' }
      resolve(result)
    }
    reader.onerror = e => reject(e)
    reader.readAsDataURL(file)
  })
}

export async function readJSONFile(ev: Event) {
  if (!ev.target) return
  const file = (ev.target as HTMLInputElement).files?.[0]
  if (!file) return
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      if (!e?.target?.result) {
        return reject(new Error('no target'))
      }
      resolve(JSON.parse(e.target.result as string))
    }
    reader.onerror = e => reject(e)
    reader.readAsText(file)
  })
}

export function getAssetPublicUrl({ filenameWithPath }: { filenameWithPath: string }): string {
  const isGoogleMode = (import.meta as any).env.VITE_COMMANDR_FILESYSTEM_MODE === 'google'

  if (isGoogleMode) {
    return `${(import.meta as any).env.VITE_COMMANDR}/${filenameWithPath}`
  }

  return commandrSDKFileSystem.getPublicUrl({ filenameWithPath })
}

export function getAssetBlob({
  filenameWithPath,
  isPrivateFile,
}: {
  filenameWithPath: string
  isPrivateFile: boolean
}): Promise<Blob> {
  return commandrSDKFileSystem.get({ filenameWithPath, isPrivateFile })
}

export async function uploadAssetImage({
  arrayBuffer,
  name,
  subFolder,
  isPrivateFile,
}: {
  arrayBuffer: ArrayBuffer
  name: string
  subFolder: string
  isPrivateFile: boolean
}): Promise<{ bucketPath: string }> {
  const uint8Array = new Uint8Array(arrayBuffer)
  const filenameWithPath = `assets/images/${subFolder}/${name}`

  await commandrSDKFileSystem.upload({ buffer: uint8Array, filenameWithPath, isPrivateFile })

  return { bucketPath: filenameWithPath }
}

export async function cloneAssetImage({
  existingPath,
  name,
  subFolder,
  isPrivateFile,
}: {
  existingPath: string
  name: string
  subFolder: string
  isPrivateFile: boolean
}): Promise<{ bucketPath: string }> {
  const filenameWithPath = `assets/images/${subFolder}/${name}`

  try {
    const blob = await getAssetBlob({ filenameWithPath: existingPath, isPrivateFile })
    const buffer = await blob.arrayBuffer()
    const uint8Array = new Uint8Array(buffer)

    await commandrSDKFileSystem.upload({ buffer: uint8Array, filenameWithPath, isPrivateFile })
    return { bucketPath: filenameWithPath }
  } catch (error) {
    return { bucketPath: '' }
  }
}

export async function deleteFile({
  filenameWithPath,
  isPrivateFile,
}: {
  filenameWithPath: string
  isPrivateFile: boolean
}): Promise<void> {
  return commandrSDKFileSystem.deleteFile({ filenameWithPath, isPrivateFile })
}

export async function deleteFolder({
  filenameWithPath,
  isPrivateFolder,
}: {
  filenameWithPath: string
  isPrivateFolder: boolean
}): Promise<void> {
  return commandrSDKFileSystem.deleteFolder({ filenameWithPath, isPrivateFolder })
}
