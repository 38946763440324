import type { TemplateLayout, LayoutNode } from '../../../builder'
import { parseNode, isComponentNode } from '../utils'

function isCurrencyUnit(unit: string) {
  if (!unit) return false
  if (['$', '€', '£'].includes(unit)) return true
  if (unit.length === 3) return true
  if (unit === 'shareCurrency') return true
  return false
}
function replaceCurrencyUnit(options: any) {
  const currency = options.unit
  options.unit = 'currencySymbol'
  options.symbol = currency
  return options
}

export default function migrateLayout(layout: TemplateLayout): TemplateLayout {
  layout.nodes = layout?.nodes.map(n =>
    parseNode(n, node => {
      if (!isComponentNode(node)) {
        return node
      }
      if (!node.options) {
        return node
      }
      if (node.options.unit) {
        if (isCurrencyUnit(node.options.unit)) {
          node.options = replaceCurrencyUnit(node.options)
        }
      }
      if (node.options?.formatTable) {
        if (node.options?.formatTable?.mode === 'columns') {
          const formats = node.options.formatTable?.formats
          if (formats) {
            Object.keys(formats).forEach(key => {
              if (isCurrencyUnit(formats[key].unit)) {
                formats[key] = replaceCurrencyUnit(formats[key])
              }
            })
            node.options.formatTable.formats = formats
          }
        }
      }
      if (node.component === 'characteristics' && node.options.format) {
        Object.keys(node.options.format).forEach(key => {
          const format = node.options.format[key]
          if (isCurrencyUnit(format.unit)) {
            node.options.format[key] = replaceCurrencyUnit(format)
          }
        })
      }
      return node
    }),
  ) as LayoutNode[]
  return layout
}
