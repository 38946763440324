<template>
  <transition @leave="leave">
    <loader v-if="loading" />
  </transition>

  <div class="header">
    <div class="column" style="gap: 8px">
      <div class="row">
        <h1 style="margin-right: 0">
          {{ `${$root.t[`${props.folderType}_items_page`].title}: ${contentFolder?.name}` }}
        </h1>
      </div>
      <p class="subtitle">
        {{ $root.t[`${props.folderType}_items_page`].subtitle }}
      </p>
    </div>
  </div>

  <div class="expand block" v-if="!loading">
    <div class="container">
      <span v-if="!formattedContentItems.length" style="margin: 16px">
        {{ $root.t[`${props.folderType}_items_page`].no_items }}
      </span>

      <div v-else style="display: flex; flex-direction: row">
        <div class="expand">
          <spreadsheet
            v-if="formattedContentItems?.length"
            class="stripped"
            :data="formattedContentItems"
            :options="{
              columns: dynamicColumns,
              editable: false,
            }"
          >
            <template v-slot:cell-fund-id="{ line }">
              <span v-if="line.isFallback" class="fallback-tag">
                {{ $root.t[`${props.folderType}_items_page`].fallback_tag_label }}
              </span>
            </template>
            <template v-slot:cell-actions="{ line }">
              <div class="row" style="align-items: end">
                <button class="ghost" :tt="$root.t[`${props.folderType}_items_page`].edit" @click="editItem(line.id)">
                  <ui-asset name="icon_edit" color="var(--colors-text)" />
                </button>
                <button
                  class="ghost"
                  :tt="$root.t[`${props.folderType}_items_page`].delete"
                  @click="setConfirmDeleteItemId(line.id)"
                >
                  <ui-asset name="icon_trash" color="var(--colors-text)" />
                </button>
                <button
                  class="ghost"
                  :tt="$root.t[`${props.folderType}_items_page`].preview"
                  @click="previewItem(line.id)"
                >
                  <ui-asset name="icon_eye" color="var(--colors-text)" />
                </button>
              </div>
            </template>
          </spreadsheet>
        </div>

        <div class="content-item-preview-panel" v-if="previewedItem">
          <div class="display: flex; flex: 1">
            <template v-if="props.folderType === 'image'">
              <img class="preview-image" :src="previewedItem.publicUrl" alt="Image preview" />
            </template>
            <template v-else>
              <p class="preview-text">{{ previewedItem.content }}</p>
            </template>
            <div class="close-button">
              <button @click="closeItemPreview">
                <ui-asset name="icon_cross" :width="18" :height="18" color="var(--colors-text)" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="gap: 8px">
      <button class="secondary" @click="cancel">
        {{ $root.t[`${props.folderType}_items_page`].cancel }}
      </button>
      <button class="primary" @click="createItem">
        {{ $root.t[`${props.folderType}_items_page`].add }}
      </button>
    </div>
  </div>

  <popup :show="showCreateItemPopup">
    <template v-slot:header>
      {{
        form.itemId
          ? $root.t[`${props.folderType}_items_page`].update_item
          : $root.t[`${props.folderType}_items_page`].create_item
      }}
    </template>
    <template v-slot:content>
      <div class="form">
        <div class="row">
          <template v-if="props.folderType === 'image'">
            <image-content :folder-type="props.folderType" :file-name="form.file?.name" @file-selected="importFile" />
          </template>
          <template v-else>
            <narrative-content
              :folder-type="props.folderType"
              :value="form.content"
              @update:value="(value: string) => (form.content = value)"
            />
          </template>
        </div>
        <div class="form-fallback">
          <input type="checkbox" v-model="form.isFallback" />
          {{ $root.t[`${props.folderType}_items_page`].is_fallback }}
        </div>
        <div v-if="!form.isFallback" class="form-variables">
          <content-item-variable-inputs
            v-for="param in variablesInputs"
            :key="param.name"
            :variableDefinition="param"
            :missingDependencies="missingDependencies?.[param.name]"
            :inputData="inputData?.[param.name]"
            :value="form.variables?.[param.name]"
            @update:value="
              value => {
                form.variables[param.name] = value
                updateVariable(param, value)
              }
            "
          />
        </div>
      </div>
    </template>
    <template v-slot:action>
      <button class="secondary-action" @click="showCreateItemPopup = false">
        {{ $root.t[`${props.folderType}_items_page`].cancel }}
      </button>
      <button class="main-action" :disabled="isSaving" @click="saveItem">
        <loading-icon v-if="isSaving" color="white" size="24" />
        <span v-else>
          {{
            form.itemId
              ? $root.t[`${props.folderType}_items_page`].update
              : $root.t[`${props.folderType}_items_page`].create
          }}
        </span>
      </button>
    </template>
  </popup>

  <popup :show="confirmDeleteItemId">
    <template v-slot:header>
      {{ $root.t.confirmation_remove }}
    </template>
    <template v-slot:content>
      {{ $root.t[`${props.folderType}_items_page`].delete_confirmation }}
    </template>
    <template v-slot:action>
      <div class="row" style="margin-top: 16px">
        <button class="secondary-action" @click="confirmDeleteItemId = null">
          {{ $root.t[`${props.folderType}_items_page`].cancel }}
        </button>
        <button class="main-action" @click="deleteContentItem(confirmDeleteItemId || NaN)">
          {{ $root.t[`${props.folderType}_items_page`].delete }}
        </button>
      </div>
    </template>
  </popup>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'

import specialtyService, { Specialty } from '@100-m/hauru/src/services/SpecialtyService'
import { useDrVariables, VariableDefinition } from '@100-m/hauru/src/components/form/drVariables/lib'
import {
  deleteFile,
  getAssetPublicUrl,
  uploadAssetImage,
  cloneAssetImage,
} from '@100-m/hauru/src/applications/builder/lib/assetManagement'
import contentFolderService, { ContentFolder } from '@100-m/hauru/src/services/ContentFolderService'
import contentItemService from '@100-m/hauru/src/services/ContentItemService'

const props = defineProps<{
  folderType: 'image' | 'narrative'
}>()

const contentFolder = ref<ContentFolder | null>(null)
const loading = ref<boolean>(false)
const showCreateItemPopup = ref<boolean>(false)
const confirmDeleteItemId = ref<number | null>(null)
const specialty = ref<Specialty | null>(null)
const variablesInputs = ref<VariableDefinition[]>([])
const previewedItem = ref<{ id: number; publicUrl?: string; content?: string } | null>(null)
const isSaving = ref<boolean>(false)

const form = ref<{
  itemId?: number
  content?: string
  file?: { name?: string; path?: string; buffer?: ArrayBuffer }
  variables: Record<string, string> | null
  isFallback: boolean
}>({
  itemId: undefined,
  content: undefined,
  file: undefined,
  variables: {},
  isFallback: false,
})

const { partials, runParameters, inputData, missingDependencies, initDrVariables, updateVariable } = useDrVariables()

onMounted(async () => {
  loading.value = true

  const contentFolderId = typeof $root.$route.query.id === 'string' && parseInt($root.$route.query.id)

  if (contentFolderId) {
    contentFolder.value = await contentFolderService.getById({ id: contentFolderId })

    specialty.value = await specialtyService.getByName(contentFolder.value?.specialtyName)

    await initDrVariables(
      specialty.value?.settings.filter(v => contentFolder.value?.variables?.includes(v.name)),
      {},
    )

    variablesInputs.value = [...partials.value, ...runParameters.value]
  }

  loading.value = false
})

const formattedContentItems = computed(() => {
  return (
    contentFolder.value?.contentItems.map(contentItem => ({
      id: contentItem.id,
      isFallback: !Object.keys(contentItem.variables || {}).length,
      ...contentItem.variables,
    })) || []
  )
})

const dynamicColumns = computed(() => {
  if (!contentFolder.value?.contentItems) return []

  const variables = specialty.value?.settings.flatMap((v: { name: string }) =>
    contentFolder.value?.variables?.includes(v.name) ? [v.name] : [],
  )

  // Handle specific case: for manager image folder, items have a property "name" which doesn't belong to specialties
  const isManagerFolder = !!contentFolder.value?.contentItems?.[0]?.variables?.name

  return [...(variables || []), ...(isManagerFolder ? ['name'] : []), 'actions']
})

const isSaveDisabled = computed(() => {
  if (props.folderType === 'image') {
    return !form.value.file?.path && !form.value.file?.buffer
  }

  return !form.value.content
})

async function deleteContentItem(contentItemId: number) {
  const deletedContentItem = await contentItemService.deleteOne({ id: contentItemId })

  if (deletedContentItem.id) {
    await deleteFile({
      filenameWithPath: deletedContentItem.value,
      isPrivateFile: false,
    })

    contentFolder.value.contentItems = contentFolder.value?.contentItems.filter(
      contentItem => contentItem.id !== contentItemId,
    )

    $root.toast({
      description: $root.t[`${props.folderType}_items_page`].delete_item_success,
      type: 'success',
      timeout: 5000,
    })

    confirmDeleteItemId.value = null

    closeItemPreview()
  }
}

async function importFile(event: Event) {
  const file = (event.target as HTMLInputElement).files?.[0]

  if (!file) {
    return $root.toast({
      description: $root.t[`${props.folderType}_items_page`].input_error,
      type: 'error',
      timeout: 5000,
    })
  }

  const validExtensions = ['jpg', 'jpeg', 'png']
  const fileExtension = file.name.split('.').pop() || ''

  if (!validExtensions.includes(fileExtension.toLowerCase())) {
    return $root.toast({
      description: $root.t[`${props.folderType}_items_page`].invalid_file_type,
      type: 'error',
      timeout: 5000,
    })
  }

  const reader = new FileReader()
  reader.onload = async event => {
    const arrayBuffer = event.target?.result

    if (!arrayBuffer) {
      return
    }

    form.value.file = { name: file.name, buffer: arrayBuffer as ArrayBuffer }
  }

  reader.readAsArrayBuffer(file)
}

function setConfirmDeleteItemId(itemId: number | null) {
  confirmDeleteItemId.value = itemId
}

function previewItem(id: number) {
  const contentItem = contentFolder.value?.contentItems.find(item => item.id === id)

  if (!contentItem) {
    return
  }

  if (props.folderType === 'image') {
    const publicUrl = getAssetPublicUrl({ filenameWithPath: contentItem.value })

    previewedItem.value = { id, publicUrl }

    return
  }

  previewedItem.value = { id, content: contentItem.value }
}

function closeItemPreview() {
  previewedItem.value = null
}

function createItem() {
  form.value = {
    itemId: undefined,
    file: undefined,
    variables: {},
    isFallback: false,
  }

  showCreateItemPopup.value = true
}

function editItem(itemId: number) {
  const item = contentFolder.value?.contentItems.find(i => i.id === itemId)
  const isFallback = !Object.keys(item?.variables || {}).length

  form.value = {
    itemId,
    variables: item?.variables || {},
    ...(props.folderType === 'image'
      ? {
          file: {
            name: item?.value.split('/').pop(),
            path: item?.value,
          },
        }
      : { content: item?.value }),
    isFallback,
  }

  showCreateItemPopup.value = true
}

async function saveItem(): Promise<void> {
  isSaving.value = true

  if (!form.value.isFallback && !Object.keys(form.value.variables || {}).length) {
    isSaving.value = false

    return $root.toast({
      description: $root.t[`${props.folderType}_items_page`].no_variables_selected,
      type: 'error',
      timeout: 5000,
    })
  }

  const bucketPath = props.folderType === 'image' ? await uploadFormFile() : null

  const contentItemInput = {
    value: props.folderType === 'image' ? bucketPath : form.value.content,
    contentFolderId: contentFolder.value?.id,
    variables:
      !form.value.isFallback && Object.keys(form.value.variables || {}).length ? form.value.variables : undefined,
  }

  try {
    if (form.value.itemId) {
      await contentItemService.update({ id: form.value.itemId, contentItemInput })

      contentFolder.value.contentItems = contentFolder.value?.contentItems.map(item =>
        item.id === form.value.itemId
          ? { ...item, value: contentItemInput.value, variables: contentItemInput.variables }
          : item,
      )
    } else {
      const res = await contentItemService.create({ contentItemInput })

      contentFolder.value.contentItems = [
        ...(contentFolder.value?.contentItems || []),
        {
          id: res.id,
          contentFolderId: contentFolder.value?.id || NaN,
          value: contentItemInput.value ?? '',
          variables: contentItemInput.variables ?? {},
        },
      ]
    }
  } catch (error) {
    isSaving.value = false

    return $root.toast({ description: error?.message, type: 'error', timeout: 5000 })
  }

  isSaving.value = false
  showCreateItemPopup.value = false

  $root.toast({
    description: form.value.itemId
      ? $root.t[`${props.folderType}_items_page`].update_item_success
      : $root.t[`${props.folderType}_items_page`].create_item_success,
    type: 'success',
    timeout: 5000,
  })

  $root.$router.push({
    path: $root.appath + `${props.folderType}-folder`,
    query: { id: contentFolder.value?.id?.toString() },
  })
}

const uploadFormFile = async () => {
  const isFallback = !Object.keys(form.value.variables || {}).length

  const fileExtension = form.value.file?.name?.split('.').pop()

  const filename = `${contentFolder.value?.name}-${
    isFallback
      ? 'fallback'
      : Object.values(form.value.variables || {})
          .filter(v => !!v)
          .join('-')
  }.${fileExtension}`

  if (form.value.file?.buffer) {
    const { bucketPath } = await uploadAssetImage({
      arrayBuffer: form.value.file.buffer as ArrayBuffer,
      name: filename,
      subFolder: contentFolder.value?.name || '',
      isPrivateFile: false,
    })

    if (!bucketPath) {
      return $root.toast({
        description: $root.t[`${props.folderType}_items_page`].upload_error,
        type: 'error',
        timeout: 5000,
      })
    }

    return bucketPath
  }

  if (form.value.file?.path && filename !== form.value.file.name) {
    const oldPath = form.value.file.path

    const { bucketPath } = await cloneAssetImage({
      existingPath: oldPath,
      name: filename,
      subFolder: contentFolder.value?.name || '',
      isPrivateFile: false,
    })

    if (!bucketPath) {
      return $root.toast({
        description: $root.t[`${props.folderType}_items_page`].upload_error,
        type: 'error',
        timeout: 5000,
      })
    }

    await deleteFile({
      filenameWithPath: oldPath,
      isPrivateFile: false,
    })

    return bucketPath
  }

  return form.value.file?.path || undefined
}

// ⚠️ Doesn't go back to prevent issues with hauru router overrides
function cancel(): void {
  $root.$router.push({
    path: $root.appath + `${props.folderType}-folders`,
  })
}
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.subtitle {
  font-style: italic;
  margin-top: -5px;
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: row;
}
:deep(.modal-container) {
  width: 550px !important;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 12px;
  margin-bottom: 24px;
}
.form-variables {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.form-variables :deep(input) {
  width: 100%;
}
.filename {
  flex: 1;
}
.form-fallback {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.content-item-preview-panel {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--colors-gray1);
  margin-top: 24px;
  height: fit-content;
  padding: 6px 6px;
}
.content-item-preview-panel .preview-image {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: auto;
  width: 250px;
}
.content-item-preview-panel .preview-text {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 70vh;
  overflow: scroll;
  width: 350px;
}
.content-item-preview-panel .close-button {
  position: absolute;
  right: 20px;
  top: 48px;
}
.content-item-preview-panel .close-button > button {
  background: rgba(233, 233, 233, 0.6);
  padding: 6px;
  width: 32px;
  height: 32px;
}
.fallback-tag {
  width: auto !important;
  height: 20px !important;
  font-size: 9px !important;
  padding: 1px 6px !important;
  margin-left: 8px !important;
  line-height: 18px !important;
  border-radius: 3px;
  border: 1px solid black;
}
</style>
