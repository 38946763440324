<template>
  <builder-chart v-bind="{ ...props, data, nxChartOptions, chartOptions }">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { bar as api } from '../composables/builderOptions'
import { BuilderProps } from '../composables/builderComponent'
import { bar } from './stories'
import useTranslations from '../composables/translations'

const viz = 'bar'
const props = defineProps<BuilderProps>()
const { translateData } = useTranslations(props)

const componentVersion = {
  v1: (horizontal: boolean) => (horizontal ? 'nx' : 'dx'),
  v2: () => 'nx',
}

const nxChartOptions = computed(() => {
  const version = props.options.version || 'v1'
  const horizontal = props.options.horizontal
  const lib = componentVersion[version] ? componentVersion[version](horizontal) : 'nx'

  if (version === 'v2') {
    return {
      viz,
      lib,
      legendOptions: { position: 'bottom' },
      variant: props.options?.variant || 'auto',
      axisXValuePosition: props.options?.axisXValuePosition || 'normal',
      axisYValuePosition: props.options?.axisYValuePosition || 'normal',
      negativeLabelBehaviour: props.options?.negativeLabelBehaviour || 'normal',
      stacked: props.options?.stacked || false,
      label_position_stacked: props.options?.label_position_stacked || false,
      version,
      maxAxisValue: props.options.maxAxisValue || null,
      limit: null,
      other: false,
    }
  }
  return {
    viz,
    lib,
    legendOptions: { position: 'bottom' },
    version,
  }
})
function parseLegendPosition(position?: string) {
  if (!position || position === 'default') return 'bottom'
  if (position === 'floating') return 'absolute'
  return position
}

const chartOptions = computed(() => {
  return {
    legendOptions: {
      position: parseLegendPosition(props.options.legendPosition),
      shape: props.options?.legendShape || 'square',
      orientation: props.options?.legendOrientation || 'horizontal',
      variant: props.options?.variant || 'bar',
    },
  }
})
</script>

<script lang="ts">
export default {
  api,
  viz: 'bar',
  styles: {
    'bar-container': {
      name: 'Bar container',
      css: `.y-grid .tick line {
      stroke: lightgrey;
      stroke-opacity: 0.7;
      stroke-dasharray: dashed;
      }`,
    },
    'bar-bar': {
      name: 'Bar',
      css: `.group-0 { fill: var(--primary)}`,
    },
    'bar-label': {
      name: 'Bar label',
      css: `font-size:12px;
                fill: black;`,
    },
    'x-axis': {
      name: 'X-axis',
      css: `.tick text{fill:grey;}`,
    },
    'y-axis': {
      name: 'Y-axis',
      css: `.tick text{fill:grey;}`,
    },
    '.builder-bar .legend-value': {
      name: 'Legend Value',
      css: '',
    },
    '.builder-bar .legend-label': {
      name: 'Legend Label',
      css: '',
    },
    '.builder-bar .nx-chart-legend': {
      name: 'Legend',
      css: '',
    },
  },
  story: bar,
}
</script>
