const defaultContext = {
  translations: {
    name: 'Name',
  },
  variables: {
    lang: 'en',
  },
  node: {},
}

export const footer = {
  props: {
    context: {
      ...defaultContext,
      path: [0],
      translations: {
        footnote_1: 'Footnote 1',
        footnote_1_text: 'Footnote 1 Text, long text that should be displayed in the footer',
        footnote_2: 'Footnote 2',
        footnote_2_text: 'Footnote 2 Text, long text that should be displayed in the footer',
      },
      pageFootnotes: [
        {
          key: 'footnote_1',
          value: 'footnote_1_text',
        },
        {
          key: 'footnote_2',
          value: 'footnote_2_text',
        },
      ],
    },
    options: {
      disclaimer: 'This is a disclaimer',
    },
  },
}

export const bar = {
  props: {
    options: {
      x: 'key',
      y: 'value',
      category: 'category',
      horizontal: false,
      unit: '%',
      digit: 1,
      version: 'v2',
      variant: 'bar',
    },
    data: [
      { key: 'Category 1', value: 0.5, category: 'Fund' },
      { key: 'Category 2', value: 0.3, category: 'Fund' },
      { key: 'Category 3', value: 0.2, category: 'Fund' },
      { key: 'Category 1', value: 0.42, category: 'Benchmark' },
      { key: 'Category 2', value: 0.01, category: 'Benchmark' },
      { key: 'Category 3', value: 0.24, category: 'Benchmark' },
      // { key: 'Category 4', value: 0.5, category: 'Fund' },
      // { key: 'Category 5', value: 0.3, category: 'Fund' },
      // { key: 'Category 6', value: 0.2, category: 'Fund' },
      // { key: 'Category 4', value: 0.42, category: 'Benchmark' },
      // { key: 'Category 5', value: 0.01, category: 'Benchmark' },
      // { key: 'Category 6', value: 0.24, category: 'Benchmark' },
      // { key: 'Category 7', value: 0.5, category: 'Fund' },
      // { key: 'Category 8', value: 0.3, category: 'Fund' },
      // { key: 'Category 9', value: 0.2, category: 'Fund' },
      // { key: 'Category 7', value: 0.42, category: 'Benchmark' },
      // { key: 'Category 8', value: 0.01, category: 'Benchmark' },
      // { key: 'Category 9', value: 0.24, category: 'Benchmark' },
    ],
    context: defaultContext,
  },
}

export const pie = {
  props: {
    options: {
      x: 'key',
      y: 'value',
      unit: '%',
      digit: 2,
      legend: true,
    },
    data: [
      { key: 'Category 1', value: 0.5 },
      { key: 'Category 2', value: 0.3 },
      { key: 'Category 3', value: 0.2 },
      { key: 'Category 4', value: 0.5 },
      { key: 'Category 5', value: 0.3 },
      { key: 'Category 6', value: 0.2 },
    ],
    context: defaultContext,
  },
}

function makePerfArray(category: string) {
  let date = new Date('2021-01-01')
  let nav = 100
  let trend = 0 // Initialize trend
  let step = 0 // Step counter

  return Array.from({ length: 100 }, (_, i) => {
    date = new Date(date.setDate(date.getDate() + 1))

    // Adjust trend based on current step
    if (step === 0) {
      trend += 0.2 + (Math.random() - 0.5) * 0.2 // Slightly up
      if (i % 33 === 0) step++ // Move to next step after 33 iterations
    } else if (step === 1) {
      trend += 0.5 + (Math.random() - 0.2) * 2 // A lot up
      if (i % 33 === 0) step++ // Move to next step after 33 iterations
    } else {
      trend += (Math.random() - 0.5) * 2 - 0.5 // A lot down
    }

    nav += trend / 100 + (Math.random() - 0.5) * 0.5 // Add trend and noise
    return {
      date,
      value: nav,
      category,
    }
  }).slice(0, -1)
}
export const line = {
  props: {
    options: {
      x: 'date',
      y: 'value',
      category: 'category',
      digit: 0,
      legend: true,
      showLegendValues: 'evolution',
    },
    data: makePerfArray('Fund').concat(makePerfArray('Benchmark')),
    context: defaultContext,
  },
}

export const characteristics = {
  props: {
    options: {
      values: ['Fund Name', 'Fund Manager', 'Category', 'Benchmark', 'Fund Inception'],
    },
    data: {
      'Fund Name': 'Fund XYZ',
      'Fund Manager': 'Manager ABC',
      Category: 'Bonds',
      Benchmark: '50% Composite Index and 50% MSCI Europe',
      'Fund Inception': '02 February 2016',
    },
    context: defaultContext,
  },
}

export const table = {
  props: {
    options: {
      striping: 'even',
    },
    context: defaultContext,
    data: [
      { name: 'Instrument A', sector: 'Sector 1', assetClass: 'Equity', weight: 0.05 },
      { name: 'Instrument B', sector: 'Sector 1', assetClass: 'Equity', weight: 0.042 },
      { name: 'Instrument C', sector: 'Sector 2', assetClass: 'Fixed Income', weight: 0.03 },
      { name: 'Instrument D', sector: 'Sector 2', assetClass: 'Fixed Income', weight: 0.02 },
      { name: 'Instrument E', sector: 'Sector 3', assetClass: 'Fixed Income', weight: 0.015 },
    ],
  },
}

export const heatmap = {
  props: {
    options: {
      x: 'sector_gics_1',
      y: 'currency',
      z: 'weight',
      unit: '%',
      digit: 1,
      xLimit: 3,
      yLimit: 6,
      showXOther: true,
      showXTotal: true,
      showYOther: true,
      showYTotal: true,
    },
    context: defaultContext,
    data: [
      { sector_gics_1: 'Industrials', currency: 'USD', weight: 0.2466 },
      { sector_gics_1: 'Materials', currency: 'USD', weight: 0.053 },
      { sector_gics_1: 'Technology', currency: 'USD', weight: 0.0969 },
      { sector_gics_1: 'Utilities', currency: 'USD', weight: 0.0365 },
      { sector_gics_1: 'Industrials', currency: 'EUR', weight: 0.1305 },
      { sector_gics_1: 'Materials', currency: 'EUR', weight: 0.0799 },
      { sector_gics_1: 'Technology', currency: 'EUR', weight: 0.044 },
      { sector_gics_1: 'Utilities', currency: 'EUR', weight: 0.0732 },
      { sector_gics_1: 'Industrials', currency: 'SEK', weight: 0.0289 },
      { sector_gics_1: 'Technology', currency: 'SEK', weight: 0.0259 },
      { sector_gics_1: 'Utilities', currency: 'SEK', weight: 0.0092 },
      { sector_gics_1: 'Industrials', currency: 'JPY', weight: 0.0204 },
      { sector_gics_1: 'Industrials', currency: 'KRW', weight: 0.0215 },
      { sector_gics_1: 'Materials', currency: 'KRW', weight: 0.0058 },
      { sector_gics_1: 'Industrials', currency: 'DKK', weight: 0.0155 },
      { sector_gics_1: 'Utilities', currency: 'DKK', weight: 0.0087 },
      { sector_gics_1: 'Materials', currency: 'NOK', weight: 0.0115 },
    ],
  },
}

export const sdgIndicatorStory = {
  props: {
    options: {
      showSdgLogo: true,
      legend: true,
      horizontal: true,
    },
    data: [
      {
        key: 'benchmark',
        value: 133,
        indicator: 'sdg1-no_poverty',
        coverage: 0.83,
        unit: '$',
      },
      {
        key: 'fund',
        value: 141,
        indicator: 'sdg1-no_poverty',
        coverage: 0.73,
        unit: '$',
      },
    ],
    context: defaultContext,
  },
}

export const morningstar = {
  props: {
    context: defaultContext,
    options: {
      showLabel: true,
      showLogo: true,
      starSize: 4,
    },
    data: {
      morningstar_rating: 5,
    },
  },
}

export const sri = {
  props: {
    context: defaultContext,
    options: {
      showLabel: true,
      shape: 'circle',
      width: 25,
      height: 25,
      circleRadius: 25,
      space: 1,
      strokeSize: 2,
      displayArrow: true,
      arrowType: 'fill',
      arrowHeadSize: 3,
      showLegend: true,
      legendPosition: 'bottom',
    },
    data: {
      srri: 4,
    },
  },
}

export const section = {
  props: {
    context: {
      ...defaultContext,
      translations: {
        section_title_1: 'Section 1',
      },
    },
    options: {
      title: 'section_title_1',
    },
  },
}

export const tableOfContent = {
  props: {
    context: {
      ...defaultContext,
      node: {
        component: 'table-of-content',
        type: 'block',
        options: {},
      },
      layout: {
        nodes: [
          {
            type: 'column',
            nodes: [
              { component: 'section', type: 'block', options: { title: 'Section 1' } },
              { component: 'footer', type: 'block' },
            ],
          },
          {
            type: 'column',
            nodes: [
              { component: 'section', type: 'block', options: { title: 'Section 2' } },
              { component: 'footer', type: 'block' },
            ],
          },
        ],
      },
    },
    options: {
      title: 'Content',
    },
  },
}

export const bubble = {
  props: {
    options: {
      x: 'percentage_women_global',
      y: 'percentage_women_management',
      z: 'bubble_size',
      groupBy: 'group',
      unit: '%',
      digit: 1,
      xTitle: '% Women Global',
      yTitle: '% Women Management',
    },
    context: defaultContext,
    data: [
      {
        key: 'overall_staff',
        group: 'Company A',
        indicator: 'gender_balance',
        percentage_women_global: 0.23,
        percentage_women_management: 0.4,
        bubble_size: 10,
        date: '2024-12-31',
        provider: 'HR Analytics',
      },
      {
        key: 'overall_staff',
        group: 'Company B',
        indicator: 'gender_balance',
        percentage_women_global: 0.45,
        percentage_women_management: 0.5,
        bubble_size: 18,
        date: '2024-12-31',
        provider: 'HR Analytics',
      },
      {
        key: 'overall_staff',
        group: 'Company C',
        indicator: 'gender_balance',
        percentage_women_global: 0.36,
        percentage_women_management: 0.32,
        bubble_size: 12,
        date: '2024-12-31',
        provider: 'HR Analytics',
      },
    ],
  },
}

export const managerImage = {
  props: {
    options: {
      nameOrder: '1 line: name firstname',
      imagePosition: 'top',
      showTitle: true,
      layoutDirection: 'row',
    },
    data: {
      firstname: 'Jane',
      lastname: 'Doe',
      title: 'Manager',
      image: 'https://fr.linkedin.com/company/neoxam',
    },
  },
}

export const gauge = {
  props: {
    options: {
      primaryKey: 'fund',
      comparisonKey: 'univers',
      dualBars: true,
      unit: '%',
      digit: 2,
      primaryIndicatorLabel: 'Fund',
      comparisonIndicatorLabel: 'Universe',
    },
    context: defaultContext,
    data: [
      {
        key: 'fund',
        fuid: 'GRK001',
        indicator: 'sdg2',
        value: 100,
        coverage: 0.68,
        unit: 'tCo2',
        date: '2024-12-31',
        provider: 'Ecofi',
      },
      {
        key: 'univers',
        fuid: 'FR0001',
        indicator: 'sdg2',
        value: 100,
        coverage: 0.42,
        unit: 'tCo2',
        date: '2024-12-31',
        provider: 'Ecofi',
      },
    ],
  },
}
