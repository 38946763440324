<template>
  <div class="flex-1">
    <img v-if="imageUrl" class="builder-image m-auto h-full w-full object-contain" :src="imageUrl" />
    <div v-else class="rounded-md !bg-red-50 p-2 !outline !outline-red-500">Error with image</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { BuilderProps } from '../composables/builderComponent'
import { getAssetPublicUrl } from '../lib/assetManagement'

type PropsData = {
  value: string
}

const props = defineProps<BuilderProps>()

const imageUrl = computed(() => {
  const data = props?.data as PropsData

  if (!data?.value) {
    return null
  }

  return getAssetPublicUrl({ filenameWithPath: data.value })
})
</script>

<script lang="ts">
export default {
  styles: {
    // allow user to overrides the image style
    'builder-image': '',
  },
}
</script>
