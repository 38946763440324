import { ref, Ref, watch } from 'vue'
export default function useOverflow(
  targetRef: Ref<HTMLElement | undefined>,
  containerRef: Ref<HTMLElement | undefined>,
  onOverflow: any,
) {
  const isOverflow = ref(false)

  const isProcessed = ref(false)

  function initOverflow() {
    let ready = false
    const resizeObserver = new ResizeObserver(function () {
      if (!containerRef.value) {
        return
      }

      isOverflow.value = containerRef.value.scrollHeight > containerRef.value.clientHeight

      if (!isProcessed.value) {
        isProcessed.value = true
      }

      onOverflow(isOverflow.value)
    })

    watch(
      targetRef,
      () => {
        if (ready) return
        if (targetRef.value) {
          resizeObserver.observe(targetRef.value)
          ready = true
        }
      },
      { immediate: true },
    )
  }

  return {
    isOverflow,
    initOverflow,
    isProcessed,
  }
}

export function computeTableMaxRows(maxHeight: number, tableElement: HTMLElement) {
  const headerHeight = tableElement.querySelector('thead')?.clientHeight || 0
  const rowHeight = tableElement.querySelector('tbody tr')?.clientHeight || 0
  return Math.floor((maxHeight - headerHeight) / rowHeight)
}
