<script>
import { isTrue } from '../../../../utils/characteristics'
import { useDates } from '../../composables/useDates'
import { useTransactions } from '../../composables/useTransactions'
import { useShare } from '../../composables/useShare'
import { useProcessTable } from '../../composables/useProcessTable'
import { useGraphQL } from '../../../../utils/axios'
import config from '../../../../config'
import Error from '@100-m/hauru/src/components/ui/error.vue'
import { getAssetPublicUrl } from '../../../builder/lib/assetManagement'

export const additions = { calendar: 'period', icon: 'ic_dashboard' }
const { processTable } = useProcessTable()
const { getTransactions } = useTransactions()
const { getPerformanceDates } = useDates()
const { getShare } = useShare()

const isValidImgUrl = url => {
  return /\.(jpg|jpeg|png|webp)$/.test(url)
}

export default {
  components: { Error },
  data() {
    return {
      displayed_plot: 'performance_historical',
      loaded: false,
      error: null,
      default_domain: '0001-01-01|' + new Date().toISOString().split('T')[0],
      params: {
        isinShare: this.$route.fullPath.split('/')[2].split('-')[1],
        domain: $root.domain.join('|') || this.$route.query.domain,
        lang: this.$route.query.lang || $root.lang,
        period: 'monthly',
        externalOptions: { valuationStyle: $root.valuationStyle },
      },
      managementCommentDate: null,
      managementComment: {},
      transactions: null,
      data: null,
      share: null,
      positionCount: null,
      fundNav: null,
    }
  },
  computed: {
    overviewPlot() {
      // todo: check if really needed
      const title = 'de_' + this.displayed_plot
      let options = { format: v => format('.2%')(v * 0.01) }
      let data = []
      if (this.displayed_plot === 'performance_historical') {
        // options = { format: '.2%' }
        // Get the first benchmark in the list, if it's empty, use 'benchmark'
        // const benchmark_multiple = this.data.share.characteristics?.benchmark_multiple?.split(',')[0]
        data = this.data.performance.domainHistorical.reduce((acc, v) => {
          acc[v.date] = {
            fund: v.performance.fund - 100,
            benchmark: v.performance.benchmark - 100,
          }
          return acc
        }, {})
        // data = this.data.share.analytics.domainHistorical
        //   .filter(d => d.date >= $root.domain[0] && d.date <= $root.domain[1])
        //   .reduce((acc, a) => {
        //     let doc = {}
        //     if (a.fund) doc.fund = a.fund - 100
        //     if (a.benchmark && (!benchmark_multiple || benchmark_multiple === 'benchmark'))
        //       doc.benchmark = a.benchmark - 100
        //     if (a.benchmark2 && benchmark_multiple === 'benchmark2') doc.benchmark2 = a.benchmark2 - 100
        //     if (a.benchmark3 && benchmark_multiple === 'benchmark3') doc.benchmark3 = a.benchmark3 - 100
        //     acc[a.date] = doc
        //     return acc
        //   }, {})
      }
      if (this.displayed_plot === 'real_nav') {
        options = { format: ',' + (this.share.shareCurrency || '€') }
        data = this.fundNav.reduce((acc, a) => {
          acc[a.date] = { fund: a.realNav }
          return acc
        }, {})
      }
      if (this.displayed_plot === 'aum_fund_currency') {
        options = { format: 'M' + (this.share.shareCurrency || '€') }
        data = this.fundNav.reduce((acc, a) => {
          acc[a.date] = { fund: a.aumFund }
          return acc
        }, {})
      }
      return { title, data, options }
    },
    rootWatcher() {
      return this.$route.query.domain || $root.query.domain
    },
    tables() {
      return {
        // characteristics: processTable(this.data.nxPackTables, this.data.share, 'characteristics'),
        // customer_informations: processTable(this.data.nxPackTables, this.data.share, 'customer_informations'),
      }
    },
    performanceKpis() {
      const kpis = [
        {
          title: this.performance_type_title,
          value: this.data.performance.fund,
        },
      ]
      if (this.share.benchmarkId) {
        kpis.push({
          title: 'performance_benchmark',
          value: this.data.performance.benchmark,
        })
      }
      return kpis
    },
    lastNav() {
      if (!this.fundNav) return
      return this.fundNav[this.fundNav.length - 1]
    },
    navKpis() {
      if (!this.lastNav) return
      return [
        {
          title: 'nav',
          value: format(($root.config.formats.nav || ',') + (this.share.shareCurrency || '€'))(this.lastNav.realNav),
        },
        {
          title: this.t.asof + ' ' + new Date($root.domain[1]).format('day, mon, year', $root.lang),
        },
      ]
    },
    aumKpis() {
      if (!this.lastNav) return
      const shareCurrency = this.share.shareCurrency || '€'
      return [
        {
          title: 'aum',
          value: format('M' + shareCurrency)(parseFloat(this.lastNav.aumShare)),
        },
        {
          title: 'aum_tot_fund',
          value: format('M' + shareCurrency)(parseFloat(this.lastNav.aumFund)),
        },
        { title: this.t.asof + ' ' + new Date($root.domain[1]).format('day, mon, year', $root.lang) },
      ]
    },
    transactionsKpis() {
      if (!this.transactions) return
      const groupedTransactions = this.transactions.reduce((acc, v) => {
        if (!acc[v.type]) {
          acc[v.type] = []
        }
        acc[v.type].push(v)
        return acc
      }, {})
      const _formatKpi = (transactions, order = 'desc') => {
        if (!transactions) return []
        const sort = order === 'desc' ? '-variation' : 'variation'
        return transactions.sort(sort).map(v => ({ title: v.name, value: format('+.2%')(v.variation) }))
      }
      // NOTE: could refacto this but by iterating over the keys of groupedTransactions it might not improve readability
      return {
        combinedPurchase: _formatKpi(
          [groupedTransactions.purchase, groupedTransactions.reinforcement].filter(Boolean).flatten(),
        ),
        combinedSale: _formatKpi(
          [groupedTransactions.sale, groupedTransactions.cutback].filter(Boolean).flatten(),
          'asc',
        ),
        purchase: _formatKpi(groupedTransactions.purchase),
        reinforcement: _formatKpi(groupedTransactions.reinforcement),
        sale: _formatKpi(groupedTransactions.sale, 'asc'),
        cutback: _formatKpi(groupedTransactions.cutback, 'asc'),
      }
    },
    isFictiveMandate() {
      return isTrue(this.share && this.share.characteristics?.mandat_fictif)
    },
    managers() {
      const managersString = this.share && this.share.characteristics?.managers
      if (managersString && managersString !== '') {
        return managersString.split(',').map(manager => {
          const key = `${manager
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .replaceAll(' ', '_')}`

          const managerObject = this.share?.managers?.find(item => item.key === key)
          const imagePath = managerObject?.image?.image
          const imageUrl = imagePath ? getAssetPublicUrl({ filenameWithPath: imagePath }) : ''
          const description = managerObject?.description

          return {
            name: manager,
            description: description || $root.t[key] || $root.t[manager] || `no_data for ${key}`,
            img: isValidImgUrl(imageUrl) ? imageUrl : `${key}.jpg`,
          }
        })
      }
      return []
    },
    managerIndex() {
      return +$root.query.managers || 1
    },
    performance_type_title() {
      if (!this.share) return 'performance_brut'
      if (this.share.characteristics?.mandat_is_perf_net || this.share.characteristics?.mandat_is_perf_net === '')
        return 'performance_net'
      return 'performance_brut'
    },
  },
  watch: {
    async rootWatcher(newRoot, oldRoot) {
      if (newRoot?.toString() === oldRoot?.toString()) return
      this.params.domain = newRoot?.join('|')
      if (this.$route.query.domain === undefined) {
        this.params.domain = this.default_domain
      } else {
        this.params.domain = this.$route.query.domain
      }
      await this.getOverviewData()
    },
    async managementCommentDate(newDate, old) {
      await this.fetchManagementComment(newDate)
    },
  },
  async created() {
    await this.getOverviewData()
  },
  methods: {
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
    togglePlot(name) {
      this.displayed_plot = name
    },
    async fetchManagementComment(date) {
      const { sendQuery } = useGraphQL(config)
      const query = `#graphql
      query CommentQuery($fuid: FuidInput!, $domain: Domain!, $lang: Lang!) {
        referential {
          share(fuid: $fuid) {
            comment(domain: $domain, lang: $lang)
          }
        }
      }     
      `
      const variables = {
        fuid: this.params.isinShare,
        domain: this.params.domain,
        lang: this.params.lang,
      }
      const resp = await sendQuery(query, variables)
      this.managementComment = resp.referential.share.comment
      if (resp.referential.share?.comment) {
        this.managementCommentDate = (this.params.domain || '').slice(0, 7)
      }
    },
    changeManagementCommentMonth(date, isAdd) {
      this.managementCommentDate = this.addRemoveMonth(date, isAdd)
    },
    addRemoveMonth(date, isAdd) {
      const d = new Date(date)
      if (isAdd) d.setMonth(d.getMonth() + 1)
      else d.setMonth(d.getMonth() - 1)
      let month = '' + (d.getMonth() + 1)
      const year = d.getFullYear()
      // NOTE: this wont work month is a const
      if (month.length < 2) month = '0' + month
      return [year, month].join('-')
    },
    async getOverviewData() {
      const { sendQuery } = useGraphQL(config)
      this.loaded = false
      this.error = null
      try {
        if (!$root.dates || $root.dates.isin_share !== this.params.isinShare || $root.dates.query !== 'performance') {
          $root.dates = await getPerformanceDates(this.params)
          this.managementCommentDate = $root.dates.slice(-1)[0].slice(0, 7)
          $root.dates.isin_share = this.params.isinShare
          $root.dates.query = 'performance'
          $root.refresh = $root.refresh + 1
          this.params.domain = $root.domain.join('|')
        }
        this.share = await getShare(this.params)
        const query = `#graphql
      query Overview($fuid: FuidInput!, $domain: Domain!, $ids: FuidInput!, $benchmarkId: FuidInput!, $endDate: Domain!, $options: PerformanceHistoryOptions, $externalOptions: JSON) {
        performance {
          domainHistorical: history(fuid: $ids, domain: $domain, options: $options) {
            date
            performance
            aumFund
            aumShare
            realNav
          }
          fund: value(fuid: $fuid, domain: $domain)
          benchmark: value(fuid: $benchmarkId, domain: $domain)
        }
        inventory {
          positionCount(fuid: $fuid, domain: $endDate, externalOptions: $externalOptions)
        }
        referential {
          share(fuid: $fuid) {
            shareId
            managers
            ${$root.config.characteristicsSchema || ''}
            ${$root.config.settingsSchema || ''}
            ${$root.config.customShareSchema || ''}
          }
        }
      }`
        const ids = this.share.benchmarkId
          ? [
              { fuid: this.params.isinShare, name: 'fund' },
              { fuid: this.share.benchmarkId, name: 'benchmark' },
            ]
          : [{ fuid: this.params.isinShare, name: 'fund' }]
        const variables = {
          ids,
          fuid: this.params.isinShare,
          domain: this.params.domain,
          benchmarkId: this.share.benchmarkId || '',
          fundId: this.share.fundId,
          endDate: $root.domain[1],
          options: {
            mode: 'cumulative',
          },
          externalOptions: { valuationStyle: $root.valuationStyle },
        }
        const res = await sendQuery(query, variables)
        // Extend the default share from shareData report with the fields specific of overview screen
        this.share = {
          ...this.share,
          ...res.referential.share,
        }
        this.positionCount = res.inventory.positionCount
        this.data = res
        this.fundNav = this.data.performance.domainHistorical.map(d => {
          const row = { date: d.date }
          const valueKeys = ['performance', 'realNav', 'aumShare', 'aumFund']
          valueKeys.forEach(key => {
            row[key] = d[key].fund
          })
          return row
        })
        $root.nav_data.path = this.share.characteristics?.share_letter
        this.managementCommentDate = this.params.domain.split('|').reverse()[0].slice(0, 7)
        this.transactions = await getTransactions(this.params)
      } catch (e) {
        this.error = e
        console.error(e)
      } finally {
        this.loaded = true
      }
    },
  },
}
</script>
<template lang="pug">
transition(@leave='leave')
  loader(v-if="!this.loaded")
Error(v-if="error" :textHeader="t['error'] || 'Error'" :textBody="error.message")
template(v-if="this.loaded && !this.error")
  h1(v-if="share")
    .name {{ t[share.fundName] || share.fundName && share.fundName.titleize() || ''}}
    .share(v-if="share.characteristics?.share_letter") {{ t.share }} {{ share.characteristics?.share_letter }}
    .row
      router-link.lines(:to="{ path: 'details', query: $route.query }") {{ positionCount }} {{ t.lines }}
      .isin {{ share.shareId }}
  .row.stretch
    kpi(
      :data="performanceKpis"
      @click="togglePlot('performance_historical')" 
      :class="[{active: this.displayed_plot==='performance_historical'}]"
      aria-label="Performance KPI list"
    )
    kpi(
      :data="navKpis"
      @click="togglePlot('real_nav')" 
      :class="[{active: this.displayed_plot==='real_nav'}]"
      v-if="!isFictiveMandate"
      aria-label="NAV KPI"
      aria-controls="overview-plot"
    )
    kpi(
      :data="aumKpis"
      @click="togglePlot('aum_fund_currency')" 
      :class="[{active: this.displayed_plot==='aum_fund_currency'}]"
      v-if="!isFictiveMandate"
      aria-label="AUM KPI"
      aria-controls="overview-plot"
    )
  .row
    block(
      :title="performance_type_title"
      type="plot-line"
      :data="overviewPlot.data"
      :metadata="{format: overviewPlot.options.format}"
      @rebind="$event.yAxis.formatter(x => format(overviewPlot.options.format)(x))"
      :enableActions="false"
      id="overview-plot"
    )
  .row
    .column.expand(v-if="$root.config.displayManagers && this.managers")
      block.arrows
        template(v-slot:title)
          .row
            div
              svg-icon(
                @click="update_query({ managers: managerIndex - 1 })" 
                :class="{ inactive: managerIndex === 1 }" 
                name="ic_navigate_next" 
                style="transform: rotate(180deg)"
                aria-label="Previous manager"
              )
            .title.row 
              div {{ t.managers }}
              div ({{ managerIndex }} / {{ managers?.length }})
            div
              svg-icon(
              @click="update_query({ managers: managerIndex + 1 })"
              :class="{ inactive: managerIndex === managers?.length }" 
              name="ic_navigate_next"
              aria-label="Next manager"
            )
        .row
          .manager(v-if='managers?.[managerIndex-1]')
            img(v-if='managers?.[managerIndex-1].img' :src="managers?.[managerIndex-1].img" alt="Manager picture")
            h2 {{ managers?.[managerIndex-1].name }}
            div {{ managers?.[managerIndex-1].description }}
    .column.expand
      block(title="management_orientation")
        div(v-html="t[share.characteristics?.orientation]")
      block.arrows(v-if="$root.config.displayManagementComment")
        template(v-slot:title)
          .row
            div
              svg-icon(
                @click="changeManagementCommentMonth(managementCommentDate, false)" 
                :class="{ inactive: false }" 
                name="ic_navigate_next" 
                style="transform: rotate(180deg)"
                aria-label="Previous management comment"
              )
            .title.row 
              div {{ t.management_comment }}
              div ({{ new Date(managementCommentDate).format('month, year', $root.lang).titleize() }})
            div
              svg-icon(
                @click="changeManagementCommentMonth(managementCommentDate, true)" 
                :class="{ inactive: false }" 
                name="ic_navigate_next"
                aria-label="Next management comment"
              )
        .row
          div(v-html="managementComment?.value || t.no_data || 'No data'")
  .row
    block(title="characteristics" v-if="tables.characteristics")
      pdf-table(:data="tables.characteristics" :metadata="{noHeader: true}")
    block.arrows.expand.mgt_bloc(title="commercial_informations" v-if="tables.customer_informations")
      pdf-table(:data="tables.customer_informations" :metadata="{noHeader: true}")
  .row.stretch(v-if="transactionsKpis")
    .column
      template(v-if="$root.config.combine_in_out_mvt")
        block(title="main_in")
          kpi(:data="transactionsKpis.combinedPurchase")
      template(v-else)
        block(title="purchase_position")
          kpi(:data="transactionsKpis.purchase")
        block(title="reinforcement_position")
          kpi(:data="transactionsKpis.reinforcement")
    .column
      template(v-if="$root.config.combine_in_out_mvt")
        block(title="main_out")
          kpi(:data="transactionsKpis.combinedSale")
      template(v-else)
        block(title="sale_position")
          kpi(:data="transactionsKpis.sale")
        block(title="cutback_position")
          kpi(:data="transactionsKpis.cutback")
</template>
<style scoped>
.kpi:hover,
.active {
  cursor: pointer;
  border-color: var(--highlight);
}

.management_orientation {
  text-align: justify;
}

.block-characteristics,
.block-commercial_informations,
.block-Positions {
  min-width: 370px;
}
</style>
