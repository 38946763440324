import type { TemplateLayout } from '../../builder'
import beforeVersionningMigration from './migrations/beforeVersionning'
import unitChange from './migrations/unitChange'

interface TemplateMigration {
  version: number
  migration: (layout: TemplateLayout) => TemplateLayout
}

const migrations: TemplateMigration[] = [
  {
    version: 1,
    migration: beforeVersionningMigration,
  },
  {
    version: 2,
    migration: unitChange,
  },
]

export const lastTemplateVersion = migrations[migrations.length - 1].version
function getNeededMigrationgs(layout: TemplateLayout): TemplateMigration[] {
  if (!layout.version) return migrations
  return migrations.filter(migration => {
    return migration.version > layout.version!
  })
}

function applyMigration(layout: TemplateLayout, migration: TemplateMigration): TemplateLayout {
  const migratedLayout = migration.migration(layout)
  migratedLayout.version = migration.version
  return migratedLayout
}
export function migrateLayout(layout: TemplateLayout): TemplateLayout {
  const migrations = getNeededMigrationgs(layout)
  if (!migrations.length) return layout
  return migrations.reduce((layout, migration) => {
    try {
      return applyMigration(layout, migration)
    } catch (e) {
      console.error('Error while migrating layout', e)
      return layout
    }
  }, layout)
}
