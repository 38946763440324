<template>
  <div
    class="-page-margin font-spaceGrotesk relative !mb-2 h-fit w-[calc(100%+var(--page-margin)*2)] bg-[url('/public/ecofi/header_background.svg')] bg-cover bg-center bg-no-repeat pb-2 pl-6 pr-3 pt-3"
    style="margin: calc(-1 * var(--page-margin))"
    v-if="pageNumber === 1"
  >
    <div class="flex h-[135-px] justify-between">
      <div class="flex w-[435px] flex-col">
        <div class="font-spaceGrotesk flex h-[62px] font-bold text-white" style="font-size: 48px">GREENROCK</div>
        <div class="flex flex-row items-center justify-between pt-[3px]">
          <div class="flex flex-col justify-center text-white">
            <div class="text-[19px] font-semibold">{{ headerData.share_name }}</div>
            <div class="w-[47px] border-b-[3px] border-white pt-[3px]"></div>
            <div class="pt-[9px]">{{ headerData.amf_classification }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-end">
        <div class="pb-[14px] pt-[6px] text-[11px] uppercase text-white">
          {{ translate('header.communication_publicitaire') || 'communication_publicitaire' }}
        </div>
        <div class="flex h-[50px] flex-row items-center space-x-[12px] pt-[25px]">
          <img
            v-if="headerData?.label_greefin"
            src="/public/ecofi/Label_GreenFin.png"
            alt="logo greenfin"
            class="mx-auto h-[27px] w-auto object-contain"
          />
          <img
            v-if="headerData?.label_finansol"
            src="/public/ecofi/Label_Finansol.png"
            alt="logo finansol"
            class="h-[48px] w-auto object-contain"
          />
          <img
            v-if="headerData?.label_isr"
            src="/public/ecofi/Label_ISR.png"
            alt="logo isr"
            class="h-[48px] w-auto object-contain"
            style="margin-left: 7px !important"
          />
          <img
            v-if="headerData?.sfdr_article === '8'"
            src="/public/ecofi/sfdr_8.png"
            alt="article 8"
            class="h-[48px] w-auto object-contain"
          />
          <img
            v-if="headerData?.sfdr_article === '9'"
            src="/public/ecofi/sfdr_9.png"
            alt="article 9"
            class="h-[48px] w-auto object-contain"
          />
        </div>
        <div class="pt-[22px] text-[14px] capitalize text-white">
          {{ domain.end.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' }) }}
        </div>
      </div>
    </div>
  </div>
  <!-- page 2 and more -->
  <div
    class="items-ends relative !mb-2 flex w-[calc(100%+var(--page-margin)*2)] flex-col bg-[url('/public/ecofi/header_background.svg')] bg-cover bg-center bg-no-repeat pb-6 pl-6 pr-3 text-white"
    style="margin: calc(-1 * var(--page-margin)); height: 90px; min-height: 90px"
    v-else
  >
    <div
      class="flex h-fit w-full items-center justify-between self-end px-4 py-2 pb-0 pt-[1.2rem]"
      v-if="options.style === 'simple_date'"
    >
      <div class="text-[22px] font-bold text-white">
        <div>
          {{ headerData.share_name }}
        </div>
        <div class="text-[15px] font-normal">
          {{ translate(options.headerText) }}
        </div>
      </div>
      <div class="text-[16px] capitalize text-white">
        {{ domain.end.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' }) }}
      </div>
    </div>
    <div
      v-else
      class="flex h-fit w-full items-center justify-between self-end px-4 py-2 pb-0 pt-[2.5rem] text-[22px] text-lg font-bold text-white"
    >
      <span>{{ translate(options.headerText) }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { getHeaderData, getLogoImg } from '@100-m/hauru/src/applications/builder/lib/header'
import { parseDomainString } from '@100-m/hauru/src/applications/builder/lib/domain'
const props = defineProps(['data', 'options', 'context'])
import useTranslations from '@100-m/hauru/src/applications/builder/composables/translations'
import usePagination from '@100-m/hauru/src/applications/builder/composables/pagination'
const { pageCount, pageNumber } = usePagination(props)
const { translate } = useTranslations(props)

const headerData = computed(() => {
  try {
    return getHeaderData(props.context)
  } catch (e) {
    return { error: e }
  }
})

//const logoImg = computed(() => getLogoImg(props.context))
//mock logo until patch is released
const logoImg = '/public/ecofi/logo_ecofi.svg'

const domain = parseDomainString(props.context.variables.domain)
</script>
<script lang="ts">
import { BuilderOption } from '@100-m/hauru/src/applications/builder/composables/builderOptions'
const style: BuilderOption = {
  label: 'Header style',
  select: () => {
    return ['simple_date', 'simple']
  },
  default: () => 'simple_date',
  attrs: {
    type: 'text',
  },
}

const headerText: BuilderOption = {
  label: 'Header text',
  dataList: ({ context }) => {
    return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
  },
  attrs: {
    type: 'text',
  },
}

export default {
  viz: 'header',
  name: 'Ecofi header',
  api: {
    style,
    headerText,
  },
}
</script>
