<template>
  <content-folders folder-type="image" />
</template>

<script lang="ts">
export default {
  setup() {
    return {}
  },
}

export const additions = { icon: 'icon-image' }
</script>
