<template>
  <svg
    class="nx-line axis-lines h-full w-full max-w-full overflow-visible"
    xmlns="http://www.w3.org/2000/svg"
    ref="element"
  >
    <!-- Y axis ticks & lines -->
    <g v-for="(v, idx) in yTicks" :key="v" class="axis y" :transform="`translate(${margin.left},${yScale(v)})`">
      <!-- Horizontal grid line -->
      <line :x1="0" :x2="width - margin.right - margin.left" :class="`y-axis-line-${idx}`" />
      <!-- Tick label -->
      <text alignment-baseline="middle" x="-5" text-anchor="end">
        {{ props.options?.formatY(v) }}
      </text>
    </g>

    <!-- X axis ticks & lines -->
    <g
      v-for="(tickValue, idx) in xTicks"
      :key="tickValue"
      class="axis x"
      :transform="`translate(${xScale(tickValue)}, ${height - margin.bottom})`"
    >
      <!-- Vertical tick bar (short line) -->
      <line
        class="y-axis-line-0 x-axis-ticks"
        y1="0"
        y2="5"
        :stroke="props.options?.axisColor || 'rgba(0,0,0,0.2)'"
        stroke-width="1"
      />
      <!-- Tick label -->
      <!-- 
        If xLabelsOrientation === 'vertical', rotate the labels -90deg.
        Adjust text-anchor and dy/dx so the label doesn't overlap the axis.
      -->
      <text
        :text-anchor="xLabelsOrientation === 'vertical' ? 'end' : 'middle'"
        :transform="xLabelsOrientation === 'vertical' ? 'rotate(-90)' : null"
        :dy="xLabelsOrientation === 'vertical' ? '-0.3em' : '0.71em'"
        :dx="xLabelsOrientation === 'vertical' ? -6 : 0"
        y="9"
      >
        {{ props.options?.formatX(tickValue) }}
      </text>
    </g>

    <!-- Lines for each category -->
    <path
      class="line"
      v-for="(category, idx) in categories"
      :key="category"
      :d="line(category)(lineData)"
      :class="category"
      :stroke="props.options?.palette[idx]"
      fill="transparent"
    />
  </svg>
</template>

<script setup lang="ts">
import * as d3 from 'd3'
import { onMounted, ref, watch, nextTick } from 'vue'
import { getUniqueCategories, pivotData } from './data-utils'
import { generateXTicks } from '../../utils/nx-line-x-ticks.util'

const element = ref<HTMLElement>()
const props = defineProps(['data', 'options'])

const draw = (
  width: number,
  height: number,
  margin: {
    left: number
    right: number
    bottom: number
    top: number
    firstLabelOffsetToYAxis: number
    lastLabelOffsetToCanvas: number
  },
  xTicksCount: number,
  showEndTicks: boolean = false,
) => {
  const { data, options = {} } = props
  const x = options.x
  const categories = getUniqueCategories(data, options)
  const xData = data.map(v => v[x])
  const yData = data.flatMap(d => d.group.map(g => g[options.y]))

  const yExtent = d3.extent(yData.flat())
  const yMin = yExtent[0]
  const yMax = yExtent[1]

  const xExtent = d3.extent(xData)
  const xScaleNice = d3.scaleTime().domain(xExtent).nice()
  const xDomainNice = xScaleNice.domain()
  // Use a time scale for x
  const xScale = d3
    .scaleTime()
    .domain([xDomainNice[0], xExtent[1]])
    .range([margin.left + margin.firstLabelOffsetToYAxis, width - (margin.right + margin.lastLabelOffsetToCanvas)])

  let yScale = d3
    .scaleLinear()
    .domain(yExtent)
    .range([height - margin.bottom, margin.top])
    .nice()

  let xTicks

  if (!xTicksCount || xTicksCount <= 0) {
    // default 4 ticks
    xTicks = xScale.ticks(xTicksCount ? Math.abs(xTicksCount) : 4)

    // Fix a bug where if you have too many ticks you would get an extra uselss tick on the left
    const minIndex = xTicks.findIndex(v => v >= xExtent[0]) - 1
    if (minIndex > 0) {
      xTicks = xTicks.slice(minIndex)
    }
    if (showEndTicks) {
      xTicks = xTicks.slice(1, -1)
      const tickLength = xTicks[1] - xTicks[0]
      if (xTicks[0] - xExtent[0] < tickLength) xTicks = xTicks.slice(1)
      if (xExtent[1] - xTicks[xTicks.length - 1] < tickLength) xTicks = xTicks.slice(0, -1)
      xTicks.push(xExtent[0], xExtent[1])
    }
  } else {
    xTicks = generateXTicks({
      startDate: xExtent[0],
      endDate: xExtent[1],
      tickCount: xTicksCount,
      formatter: props.options?.formatX,
    })
  }

  let yTicks = yScale.ticks(5)

  // Ensure y-axis starts just below the lowest data point
  if (yTicks[0] > yMin) {
    const tickLength = yTicks[1] - yTicks[0]
    const newYExtent = [yMin - tickLength / 10, yMax]
    yScale = d3
      .scaleLinear()
      .domain(newYExtent)
      .range([height - margin.bottom, margin.top])
      .nice()
    yTicks = yScale.ticks(5)
  }

  const line = cat =>
    d3
      .line()
      .x(v => xScale(v[x]))
      .y(v => yScale(+v[cat]))

  const lineData = pivotData(data, options, categories)
  return { xScale, yScale, xTicks, yTicks, line, categories, lineData }
}

const width = ref(300)
const height = ref(150)
const margin = ref({ top: 10, bottom: 20, right: 0, left: 10, firstLabelOffsetToYAxis: 0, lastLabelOffsetToCanvas: 0 })
const xScale = ref(() => null)
const yScale = ref(() => null)
const xTicks = ref([])
const yTicks = ref([])
const xLabelsOrientation = ref('horizontal')
const line = ref(() => () => null)
const categories = ref([])
const lineData = ref([])

// Dynamically adjust left margin based on y-axis labels
const adjustLeftMargin = () => {
  if (!element.value || !yTicks.value.length) return
  const oldValue = margin.value.left

  const axisLabels = element.value.querySelectorAll('.y.axis text')

  const maxWidth = Math.max(...Array.from(axisLabels).map(d => d.getBoundingClientRect().width), 20)
  margin.value.left = maxWidth

  if (oldValue !== margin.value.left) {
    redraw()
  }
}

const adjustBottomMargin = () => {
  if (!element.value) {
    return
  }
  const oldValue = margin.value.bottom
  const axisLabels = element.value.querySelectorAll('.x.axis text')
  const bottomMargin =
    xLabelsOrientation.value !== 'vertical'
      ? 20
      : Math.max(...Array.from(axisLabels).map(d => d.getBoundingClientRect().height + 2), 50)
  if (oldValue !== bottomMargin) {
    margin.value.bottom = bottomMargin
    redraw()
  }
}

const adjustFirstAndLastXTickLabelOffset = () => {
  const firstTickLabel = element.value?.querySelector('.x.axis text')
  const lastTickLabel = element.value?.querySelector('.x.axis:last-of-type text')
  if (!element.value || !lastTickLabel || !firstTickLabel || props.options.xTicksCount <= 0) return

  const svgBounds = element.value.getBoundingClientRect()

  const firstLabelBounds = firstTickLabel.getBoundingClientRect()
  const firstLabelOffset = +(svgBounds.left + margin.value.left - firstLabelBounds.left).toFixed()

  const lastLabelBounds = lastTickLabel.getBoundingClientRect()
  const lastLabelOffset = +(lastLabelBounds.right - svgBounds.right).toFixed()

  let shouldRedraw = false

  if (firstLabelOffset !== 0) {
    margin.value.firstLabelOffsetToYAxis += firstLabelOffset
    shouldRedraw = true
  }

  if (lastLabelOffset !== 0) {
    margin.value.lastLabelOffsetToCanvas += lastLabelOffset
    shouldRedraw = true
  }

  if (shouldRedraw) {
    nextTick(() => {
      redraw()
    })
  }
}

watch(yTicks, () => {
  adjustLeftMargin()
})

watch(
  () => [props.options?.xLabelsOrientation, props.options?.xTicksCount, props.options?.showEndTicks],
  () => {
    redraw()
  },
)

function _redraw() {
  if (!element.value?.clientWidth) return
  width.value = element.value.clientWidth
  height.value = element.value.clientHeight

  xLabelsOrientation.value = props.options?.xLabelsOrientation || 'horizontal'
  const _draw = draw(width.value, height.value, margin.value, props.options.xTicksCount, props.options?.showEndTicks)

  xScale.value = _draw.xScale
  yScale.value = _draw.yScale
  xTicks.value = _draw.xTicks
  yTicks.value = _draw.yTicks
  line.value = _draw.line
  categories.value = _draw.categories
  lineData.value = _draw.lineData
  nextTick(() => {
    adjustBottomMargin()
    adjustFirstAndLastXTickLabelOffset()
  })
}

const redraw = _redraw.debounce(100)

let init = false
const resizeObserver = new ResizeObserver(() => {
  // Skip the first resize event on mount to avoid double-render
  if (!init) {
    init = true
    return
  }
  redraw()
})

onMounted(() => {
  redraw()
  element.value && resizeObserver.observe(element.value)
})
</script>

<style scoped>
.nx-line .axis text {
  font-size: calc(var(--text_size) * 0.8);
  fill: #212121;
}

.nx-line .axis {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
}

.nx-line .line {
  stroke-width: 1.5px;
}
</style>
