import type { TemplateLayout, Store, LayoutNode, ComponentNode, Stylesheet } from '../builder'
import { Ref, nextTick } from 'vue'
import stylesheetService from '@100-m/hauru/src/services/StylesheetService'

type Direction = 'top' | 'bottom' | 'left' | 'right' | 'overlay'
// @ts-expect-error Object.traverse
Object.traverse = (obj, fnLeaf, fnNode, path = [], root) => {
  if (!obj) return
  if (obj instanceof Object) {
    fnNode(obj, path, root)
    // @ts-expect-error Object.traverse
    return Object.keys(obj).forEach((k, i) => Object.traverse(obj[k], fnLeaf, fnNode, path.concat(k), root || obj))
  }
  return fnLeaf(obj, path, root)
}
const loadedStylesheets: Record<string, Stylesheet> = {}

export default function useLayout(store: Store, templateRef: Ref<TemplateLayout>) {
  function getNode(path: number[]) {
    if (!path) return
    if (path.length === 0) return templateRef.value
    let current: TemplateLayout | LayoutNode | ComponentNode | undefined = templateRef.value
    path.forEach(p => {
      // @ts-expect-error ComponentNode has no nodes
      current = current?.nodes?.[p]
    })
    return current
  }
  function pathEqual(path1: number[], path2: number[]) {
    return path1.length === path2.length && path1.every((p, i) => p === path2[i])
  }
  function getVirtualNodes(path: number[], nodes = [], offset = 1) {
    const nextPath = [path[0] + offset]
    const nextPage = getNode(nextPath)
    if (!nextPage?.isVirtual) return nodes
    const virtualNode = nextPage.nodes.find((node: any) => {
      if (!node?.virtualPath) return false
      return pathEqual(node?.virtualPath, path)
    })
    if (!virtualNode) return nodes
    return getVirtualNodes(path, [...nodes, nextPath], offset + 1)
  }
  function setActive(path: number[]) {
    store.active = path
    nextTick(() => {
      const el = document.querySelector('.ring-yellow-400')
      if (!el) return
      scrollTo({ top: el.getBoundingClientRect().top + window.pageYOffset - 160, behavior: 'smooth' })
    })
  }

  function getVirtualPages(path: number[], pages = [], offset = 1) {
    const nextPath = [path[0] + offset]
    const nextPage = getNode(nextPath)
    if (!nextPage?.isVirtual) return pages
    return getVirtualPages(path, [...pages, nextPath], offset + 1)
  }
  function updateNode(path: number[], data: any) {
    const parentNode = getNode(path.slice(0, -1))
    parentNode.nodes[path.at(-1)] = data
  }
  function updateLayout(path: number[]) {
    nextTick(() => window.dispatchEvent(new CustomEvent('builderUpdate', { detail: { path } })))
  }
  function updateAll() {
    templateRef.value.overflowPages = {}
    const pages = templateRef.value.nodes
    pages.forEach((page, i) => {
      updateLayout([i])
      // Need to updaet all pages and not just the ones under it for the footer page count
      // if (i >= +store.active[0]) {
      //   window.dispatchEvent(new CustomEvent('builderUpdate', { detail: { path: [i] } }))
      // }
    })
  }
  const pageAdd = (baseOffset = 1) => {
    const pages = templateRef.value.nodes
    const virtualPages = getVirtualPages(store.active)
    // If there are some virtual pages, need to add the page after those
    const virtualOffset = virtualPages.length || 0
    const offset = baseOffset + virtualOffset
    const newBlock = { component: 'block', type: 'block' }
    pages.splice(+store.active[0] + offset, 0, {
      type: 'column',
      nodes: [{ component: 'header', type: 'block' }, newBlock, { component: 'footer', type: 'block' }],
    })

    setActive([+store.active[0] + offset, 1])
    // Update all pages
    updateAll()
  }
  const virtualPageAdd = (parentIndex: number, node: any) => {
    const pages = templateRef.value.nodes
    const virtualPages = getVirtualPages([parentIndex])
    if (virtualPages.length) {
      pages.splice(virtualPages[0][0], virtualPages.length)
    }
    pages.splice(+parentIndex + 1, 0, {
      type: 'column',
      isVirtual: true,
      parentIndex,
      nodes: [{ component: 'header', type: 'block' }, node, { component: 'footer', type: 'block' }],
    })
    updateLayout([parentIndex])
  }
  const pageDel = (path = store.active, update = true) => {
    const pages = templateRef.value.nodes
    const virtualPages = getVirtualPages(path)
    if (virtualPages.length) {
      pages.splice(virtualPages[0][0], virtualPages.length)
    }
    pages.splice(path[0], 1)
    if (update) {
      updateAll()
    }
  }
  const blockAdd = (dir: Direction, path = store.active) => {
    const layoutNode = getNode(path.slice(0, -1))
    if (!layoutNode) return
    const isLine = layoutNode.type === 'row'
    const isColumn = layoutNode.type === 'column'
    const newBlock = { component: 'block', type: 'block' }
    // CASE 1: Add new block
    if ((isLine && dir === 'right') || (isColumn && dir === 'bottom')) {
      const i = +path.at(-1)
      layoutNode.nodes.splice(i + 1, 0, newBlock)
      store.active = path.slice(0, -1).concat(i + 1)
    }
    if ((isLine && dir === 'left') || (isColumn && dir === 'top')) {
      const i = +path.at(-1)
      layoutNode.nodes.splice(i, 0, newBlock)
      store.active = path.slice(0, -1).concat('' + i)
    }
    // CASE 2: Create new container
    if (isLine && dir === 'bottom') {
      const element = getNode(path)
      layoutNode.nodes[path.at(-1)] = { type: 'column', nodes: [element, newBlock] }
      store.active = path.concat([1])
    }
    if (isLine && dir === 'top') {
      const element = getNode(path)
      layoutNode.nodes[path.at(-1)] = { type: 'column', nodes: [newBlock, element] }
      store.active = path.concat([0])
    }
    if (isColumn && dir === 'right') {
      const element = getNode(path)
      layoutNode.nodes[path.at(-1)] = { type: 'row', nodes: [element, newBlock] }
      store.active = path.concat([1])
    }
    if (isColumn && dir === 'left') {
      const element = getNode(path)
      layoutNode.nodes[path.at(-1)] = { type: 'row', nodes: [newBlock, element] }
      store.active = path.concat([0])
    }
    // CASE 3: overlay
    if (dir === 'overlay') {
      const element = getNode(path)
      element.height = '100%'
      layoutNode.nodes[path.at(-1)] = { type: 'overlay', nodes: [element, newBlock] }
      store.active = path.concat([1])
    }
    updateLayout([path[0]])
  }
  const blockDel = (path = store.active) => {
    // columns.0.lines.0
    const containerPreviousNode = getNode(path.slice(0, -2))
    const containerNode = getNode(path.slice(0, -1))
    const virtualNodes = getVirtualNodes(path)
    // const builderNode = containerNode[path.at(-2)]
    // Delete all virtual page spawned by this node
    virtualNodes.forEach((_path: any) => {
      const pages = templateRef.value.nodes
      pages.splice(_path[0], 1)
    })
    if (containerNode?.nodes?.length === 1) containerPreviousNode.nodes.splice(+path.at(-2), 1)
    else containerNode.nodes?.splice(+path.slice(-1), 1)
    store.active = []
    updateLayout([path[0]])
  }
  const blockMove = $event => {
    const report = JSON.parse(JSON.stringify(templateRef.value))
    // @ts-expect-error Object.traverse
    Object.traverse(
      report,
      (v, path) => null,
      (obj, path, root) => {
        delete obj.path
        delete obj.tag_component
        delete obj.data_component
        if (
          (obj.columns?.length === 1 && obj.columns[0]?.component) ||
          (obj.lines?.length === 1 && obj.lines[0]?.component)
        ) {
          const singleNode = path.slice(0, -1).reduce((acc, p) => acc[p], root)
          singleNode.splice(path.at(-1), 1, (obj.columns || obj.lines)[0])
        }
        if (obj.pages?.length === 0 || obj.columns?.length === 0 || obj.lines?.length === 0) {
          const emptyNode = path.slice(0, -1).reduce((acc, p) => acc[p], root)
          emptyNode.splice(path.at(-1), 1)
        }
      },
    )
    templateRef.value = report
    // const from = $event.from.__draggable_context.element.path.concat($event.from.__draggable_context.element.lines ? 'lines' : 'columns', '' + $event.oldIndex)
    const to = $event.to.__draggable_context.element.path.concat(
      $event.to.__draggable_context.element.lines ? 'lines' : 'columns',
      '' + $event.newIndex,
    )
    store.active = to
  }

  async function getStylesheet(stylesheetName: string) {
    if (loadedStylesheets[stylesheetName]) return loadedStylesheets[stylesheetName]
    const stylesheetsWithName = await stylesheetService.findManyByName({ name: stylesheetName })
    const stylesheet = stylesheetsWithName?.[0]
    loadedStylesheets[stylesheetName] = stylesheet
    return stylesheet
  }

  return {
    pageAdd,
    pageDel,
    blockAdd,
    blockDel,
    blockMove,
    getNode,
    updateNode,
    updateLayout,
    virtualPageAdd,
    updateAll,
    loadedStylesheets,
    getStylesheet,
  }
}
