<template>
  <div
    class="-page-margin font-spaceGrotesk h-fit w-[calc(100%+var(--page-margin)*2)] pl-10 pr-2 pt-3 align-bottom"
    :style="`padding-left: calc(var(--page-margin) + 0.5rem);`"
  >
    <div class="flex items-center justify-between" v-if="options.style === 'light'">
      <div class="text-[8px] text-gray-700">
        <div v-html="translate('footer.info_Greenrock') || 'footer.info_Greenrock'"></div>
      </div>
    </div>
    <div v-else class="font-spaceGrotesk flex items-center">
      <div class="text-[8px] text-gray-700">
        <div style="white-space: nowrap" v-html="translate('footer.info_Greenrock') || 'footer.info_Greenrock'"></div>
      </div>
      <div class="ml-auto flex h-[47px] w-auto items-end justify-end">
        <img
          src="/public/ecofi/groupe_cooperative_logo.png"
          alt="groupe cooperative"
          class="h-[32px] w-auto object-contain"
        />
      </div>
      <div class="ml-4 flex h-[47px] w-auto items-end justify-end">
        <img src="/public/ecofi/footer_logo_1.jpg" alt="triman" class="h-[32px] w-auto object-contain" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import usePagination from '@100-m/hauru/src/applications/builder/composables/pagination'
import { getHeaderData, getLogoImg } from '@100-m/hauru/src/applications/builder/lib/header'
const props = defineProps(['data', 'options', 'context'])
const { pageCount, pageNumber } = usePagination(props)

function translate(key) {
  return props.context.translations[key] || key
}
</script>

<script lang="ts">
import { BuilderOption } from '@100-m/hauru/src/applications/builder/composables/builderOptions'
const style: BuilderOption = {
  label: 'footer style',
  select: () => {
    return ['logo', 'light']
  },
  default: ({ data_component }) => 'logo',
}
export default {
  api: {
    style,
  },
}
</script>
